import React from "react";
import Grid from "@mui/material/Grid";

import StyledTextField from "../../../../../../../../Components/common/Inputs";

const OtherSociety = ({userSocieties, otherSocietyData, otherSocietyDataError, dataInputChange, editable}) => {
    return (
        <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
                <StyledTextField required label={"Society Name"}  sx={{mt: 2}} name={"society_name"}
                    onChange={dataInputChange} error={!!otherSocietyDataError.society_name}
                    helperText={!!otherSocietyDataError.society_name && otherSocietyDataError.society_name}
                    value={userSocieties? userSocieties.society_name : otherSocietyData.society_name} disabled={userSocieties ? !editable : editable}/>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <StyledTextField required label={"Union Name"}
                    sx={{mt: 2}} name={"union_name"}
                    onChange={dataInputChange} error={!!otherSocietyDataError.union_name}
                    helperText={!!otherSocietyDataError.union_name ? otherSocietyDataError.union_name : ""}
                    value={userSocieties? userSocieties.union_name : otherSocietyData.union_name} disabled={userSocieties ? !editable : editable}/>
            </Grid>
        </Grid>
    )
}


export {OtherSociety}