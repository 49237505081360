import React from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import NaijaStates from "naija-state-local-government";

import AuthContext from "../../../../../../../Context/AuthContext";
import StyledButton from "../../../../../../../Components/common/Buttons";
import Form from "./components/Form";
import {useHistory} from "react-router-dom";


const MyProfile = ({setLoading, userReferees}) => {
    const { user, setToastMessage, setToastState, setToastSeverity } = React.useContext(AuthContext);
    const history = useHistory();
    const [userData, setUserData] = React.useState({});
    const [editable, setEditable] = React.useState(false);
    const [pictureChange, setPictureChange] = React.useState(false);

    const allStates = NaijaStates.states();
    const [allCities, setAllCities] = React.useState("");

    function getUserData() {
        axios
            .get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/account/dashboard/${user.user_id}/`)
            .then(function (response) {
                setUserData(response.data)
                response.data.states && setAllCities(NaijaStates.lgas(response.data.states)["lgas"])
                setMemberData({
                    first_name: response.data?.first_name,
                    other_name: response.data?.other_name,
                    last_name: response.data?.last_name,
                    telephone: response.data?.telephone,
                    email: response.data?.email,
                    occupation: response.data?.occupation,
                    date_of_birth: response.data?.date_of_birth,
                    address: response.data?.address,
                    states: response.data?.states,
                    city: response.data?.city,
                    profile_image: response.data?.profile_image,
                });
            })
            .catch(function(error) {
                setToastMessage(`${error.message}`);
                setToastSeverity("error");
                setToastState(true);
            })
    }

    const [memberData, setMemberData] = React.useState({
        first_name: "",
        other_name: "",
        last_name: "",
        telephone: "",
        email: "",
        occupation: "",
        date_of_birth: "",
        address: "",
        states: "",
        city: "",
        profile_image: "",
    })

    const [memberError, setMemberError] = React.useState({
        error: false,
        errorMessage: "",
        firstNameError: "",
        lastNameError: "",
        otherNameError: "",
        telephoneError: "",
        emailError: "",
        occupationError: "",
        DOBError: "",
        addressError: "",
        statesError: "",
        cityError: "",
        profilePictureError: "",
    })

    function handleSubmit() {
        setLoading(true);
        !pictureChange && delete memberData.profile_image;
        setMemberError({
            error: false,
            firstNameError: "",
            lastNameError: "",
            otherNameError: "",
            telephoneError: "",
            emailError: "",
            occupationError: "",
            DOBError: "",
            addressError: "",
            statesError: "",
            cityError: "",
            passwordError: "",
            password2Error: "",
            profilePictureError: "",
        })
        axios
            .patch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/account/dashboard/${user.user_id}/`, memberData, {headers:{'content-type': 'multipart/form-data'}})
            .then(function (res) {
                setLoading(false);
                getUserData();
                setToastMessage("Information updated successfully");
                setToastSeverity("success");
                setToastState(true);
            })
            .catch(function (error) {
                error.response &&
                    setMemberError({
                        error: true,
                        errorMessage: error.response.data.hasOwnProperty("detail") ? error.response.data["detail"] : "",
                        firstNameError: error.response.data.hasOwnProperty("first_name") ? error.response.data["first_name"] : "",
                        lastNameError: error.response.data.hasOwnProperty("last_name") ? error.response.data["last_name"] : "",
                        otherNameError: error.response.data.hasOwnProperty("other_name") ? error.response.data["other_name"] : "",
                        telephoneError: error.response.data.hasOwnProperty("telephone") ? error.response.data["telephone"] : "",
                        emailError: error.response.data.hasOwnProperty("email") ? error.response.data["email"] : "",
                        occupationError: error.response.data.hasOwnProperty("occupation") ? error.response.data["occupation"] : "",
                        DOBError: error.response.data.hasOwnProperty("date_of_birth") ? error.response.data["date_of_birth"] : "",
                        addressError: error.response.data.hasOwnProperty("address") ? error.response.data["address"] : "",
                        statesError: error.response.data.hasOwnProperty("states") ? error.response.data["states"] : "",
                        cityError: error.response.data.hasOwnProperty("city") ? error.response.data["city"] : "",
                        profilePictureError: error.response.data.hasOwnProperty("profile_image") ? error.response.data["profile_image"] : "",
                    })
                    setMemberData((prevState) => ({...prevState, profile_image:""}))
                !(error.response) &&
                    setToastMessage(`${error.message}`);
                    setToastSeverity("error");
                    setToastState(true);
                setLoading(false);
            })
    }

    React.useEffect(() => {
        getUserData();
    }, [])

    return (
        <Box>
            <Box sx={{backgroundColor:"#9b3f8317", paddingX:"2em", marginBottom:"2em", borderRadius:"1em", height:"12em", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                <Box sx={{display:"flex", alignItems:"center"}}>
                    <Avatar src={`${process.env.REACT_APP_BACKEND_ENDPOINT}${userData?.profile_image}`} sx={{ width:"6em", height:"6em", mr:2, backgroundColor:"#000" }} />
                    <Typography sx={{fontSize:"2em", fontWeight:500, display:{xs:"none", sm:"flex"} }}>{userData?.first_name} {userData?.other_name} {userData?.last_name}</Typography>
                </Box>
                <Box sx={{display:"flex", flexDirection:"column"}}>
                <StyledButton sx={{fontWeight:300}} size={"large"} variant={"contained"} disabled={editable} onClick={()=>setEditable(true)}>Edit Profile</StyledButton>
                <StyledButton sx={{fontWeight:300, mt:1}} size={"large"} variant={"outlined"} onClick={()=>history.push('/account/password/change')}>Change Password</StyledButton>
                </Box>
            </Box>
            <Form memberData={memberData} memberError={memberError}
                  setMemberData={setMemberData} editable={editable}
                  allStates={allStates} allCities={allCities}
                  setAllCities={setAllCities} setEditable={setEditable}
                  setPictureChange={setPictureChange} pictureChange={pictureChange} handleSubmit={handleSubmit} />
        </Box>
    )
}

export default MyProfile;