import Link from "@mui/material/Link";
import {styled} from "@mui/material";

const StyledLink = styled((props) => (
    <Link underline={"none"} color={"primary"} {...props} />
))(({theme}) => ({
    fontFamily: "'Merriweather Sans', sans-serif;",
    fontWeight: 500,
    "&:hover": {
        color: "inherit"
    }
}))

export default StyledLink;