import React from "react";
import {useLocation} from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from '@mui/material/CssBaseline';
import {styled} from "@mui/material";

import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Home from "./components/Home";
import Profile from "./components/Profile";
import Payments from "./components/Payments";
import Loader from "../../../Components/common/Loader";
import UserData from "../../../HelperFunctions/UserData";
import UserTransactions from "../../../HelperFunctions/UserTransactions";
import UserWallet from "../../../HelperFunctions/UserWallet";
import UserReferees from "../../../HelperFunctions/UserReferees";
import UserSocieties from "../../../HelperFunctions/UserSocieties";
import UserBank from "../../../HelperFunctions/UserBank";
import StyledAlert from "../../../Components/common/Alert";
import Loans from "./components/Loans";
import AuthContext from "../../../Context/AuthContext";


const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(3, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Dashboard = () => {
    const location = useLocation();
    const [loading, setLoading] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);

    const { user } = React.useContext(AuthContext);
    const userData = UserData();
    const userTransactions = UserTransactions();
    const userWallet = UserWallet();
    const userReferee = UserReferees(user.user_id);
    const userSocieties = UserSocieties(user.user_id);
    const userBank = UserBank();
    const [open, setOpen] = React.useState(false);
    const [mobileOpen, setMobileOpen] = React.useState(false);

    // console.log(userTransactions)

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleMobileDrawerOpen = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    React.useEffect(()=>{
        setIsLoading(false);
    },[])
    return isLoading ? (
        <Loader loading={isLoading} />
    ) : (
        <Box sx={{display:"flex"}}>
        {loading && <Loader loading={loading}/>}
        <CssBaseline />
        <Sidebar drawerOpen={open} handleDrawerClose={handleDrawerClose} DrawerHeader={DrawerHeader} mobileOpen={mobileOpen} handleMobileDrawerOpen={handleMobileDrawerOpen} />
        <Header setLoading={setLoading} userData={userData} userWallet={userWallet} userReferee={userReferee} userBank={userBank} drawerOpen={open} handleDrawerOpen={handleDrawerOpen} handleMobileDrawerOpen={handleMobileDrawerOpen} />
        <Box component="main" sx={{ flexGrow: 1, p: 3, mt:3, width:"100%", overflowX: "auto" }}>
            <DrawerHeader />
            {userReferee?.length===0 &&
                <StyledAlert
                    opened={true}
                    severe={"warning"}
                    title={"Referees not set"}
                    message={"Go to Profile and select Referee tab to submit your referees details"}
                />
            }
            {userReferee?.length===1 &&
                <StyledAlert
                    opened={true}
                    severe={"warning"}
                    title={"Second referee not set"}
                    message={"Go to Profile and select Referee tab to submit your second referee details"}
                />
            }
            {(!user || !(userData?.profile_image)) &&
                <StyledAlert
                    opened={true}
                    severe={"warning"}
                    title={"Profile picture not set"}
                    message={"Go to profile and add a profile picture"}
                />
            }
            {userWallet?.account_number==='' &&
                <StyledAlert
                    opened={true}
                    severe={"warning"}
                    title={"Virtual account not created"}
                    message={"Use the button above to create a virtual account, used as a wallet for your cooperative activities"}
                />
            }
            {location.pathname==='/account/user/dashboard' && <Home userData={userData} userTransactions={userTransactions} userWallet={userWallet} />}
            {location.pathname==='/account/user/dashboard/profile' && <Profile setLoading={setLoading} userReferee={userReferee} userSocieties={userSocieties} />}
            {location.pathname==='/account/user/dashboard/payments' && <Payments userData={userData} userWallet={userWallet} userTransactions={userTransactions} setLoading={setLoading} />}
            {location.pathname==='/account/user/dashboard/loans' && <Loans userData={userData} userReferee={userReferee} userWallet={userWallet} userTransactions={userTransactions} loading={loading} setLoading={setLoading} />}
        </Box>
    </Box>
    )
}

export default Dashboard;