import axios from "axios";
import React from "react";
import AuthContext from "../../Context/AuthContext";


const BanksList = () => {
    const [banks, setBanks] = React.useState(null);
    const { setToastMessage, setToastState, setToastSeverity } = React.useContext(AuthContext);
    function getBanks() {
        axios
            .get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/fetch-banks/`)
            .then(function (res) {
                setBanks(res.data.data);
            })
            .catch(function (error) {
                !(error.response?.data) &&
                    setToastMessage(`${error.message}`);
                    setToastSeverity("error");
                    setToastState(true);
            })
    }

    React.useEffect(() => {
        getBanks();
    },[])

    return banks
}

export default BanksList;