import React from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";

import {AccountNumberFormat} from "../../../../../../HelperFunctions/PhoneNumberFormat";
import StyledTextField from "../../../../../../Components/common/Inputs";
import StyledDrawer from "../../../../../../Components/common/Drawer";
import StyledButton from "../../../../../../Components/common/Buttons";
import AuthContext from "../../../../../../Context/AuthContext";
import BanksList from "../../../../../../HelperFunctions/BanksList";


const BankDetails = ({state, setState, setLoading}) => {
    const { user, setToastMessage, setToastSeverity, setToastState } = React.useContext(AuthContext);
    const banks = BanksList();

    const [accountData, setAccountData] = React.useState({
        account_name: "",
        account_number: "",
        bank_name: ""
    })
    const [accountError, setAccountError] = React.useState({
        account_nameError: "",
        account_numberError: "",
        bank_nameError: ""
    })
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState(open);
    };
    const dataInputChange = (e) => {
        setAccountData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = () => {
        setLoading(true);
        let form = document.getElementById(`bankDetailsForm`);
        if (!form.reportValidity()) {
            setLoading(false)
            return;
        }
        setAccountError({
            account_nameError: "",
            account_numberError: "",
            bank_nameError: ""
        })
        axios
            .post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/account/${user.user_id}/bank-details/`, accountData)
            .then(function(res) {
                // setLoading(false)
                setToastMessage('Account details submitted');
                setToastSeverity("success");
                setToastState(true)
                setLoading(false)
                window.location.reload()
            })
            .catch(function(error) {
                error.response &&
                    setToastMessage(`You have errors in your form`);
                    setToastSeverity("error");
                    setToastState(true);
                    setAccountError({
                        account_nameError: error.response?.data?.hasOwnProperty("account_name") ? error.response.data["account_name"] : "",
                        account_numberError: error.response?.data?.hasOwnProperty("account_number") ? error.response.data["account_number"] : "",
                        bank_nameError: error.response?.data?.hasOwnProperty("bank_name") ? error.response.data["bank_name"] : "",
                    })

                !(error.response) &&
                    setToastMessage(`${error.message}`);
                    setToastSeverity("error");
                    setToastState(true);
                setLoading(false);
            })
    }

    return (
        // <Box>
        <StyledDrawer
            anchor={'right'}
            open={state}
            onClose={toggleDrawer('right', false)}
            onOpen={toggleDrawer('right', true)}
        >
            <Typography sx={{fontWeight:600, fontSize:"2em", mb:5, textOverflow:"wrap", mt:"3em"}}>
                Submit Bank Details
            </Typography>
            <Box component={"form"} noValidate autoComplete={"off"} id={"bankDetailsForm"}>
                <StyledTextField required label={"Account name"} name={"account_name"} sx={{mt:"2em"}}
                                 onChange={dataInputChange} error={!!accountError.account_nameError}
                                 helperText={!!accountError.account_nameError ? accountError.account_nameError : ""}
                                 value={accountData?.account_name}/>
                <StyledTextField required label={"Account number"} sx={{mt:"2em"}}
                                 InputProps={{
                                     inputComponent: AccountNumberFormat,
                                 }} name={"account_number"}
                                 onChange={dataInputChange} error={!!accountError.account_numberError}
                                 helperText={!!accountError.account_numberError ? accountError.account_numberError : ""}
                                 value={accountData?.account_number}/>
                <StyledTextField required label={"Bank"} sx={{mt:"2em"}} select
                                 name={"bank_name"}
                                 onChange={dataInputChange}
                                 value={accountData?.bank_name} error={!!accountError.bank_nameError}
                                 helperText={!!accountError.bank_nameError ? accountError.bank_nameError : ""}>
                    {banks && banks.map((bank) => (
                        <MenuItem value={bank.name} key={bank.id}>
                            {bank.name}
                        </MenuItem>
                    ))}
                </StyledTextField>
                <StyledButton size={"large"} variant={"contained"} sx={{mt:"3em"}} onClick={handleSubmit}>Submit Bank Details</StyledButton>
            </Box>
        </StyledDrawer>
        // </Box>
    )
}

export default BankDetails;