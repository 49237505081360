import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import fof from "../Assets/img/error-404.png";
import Header from "../Components/common/Header";
import Typography from "@mui/material/Typography";
import StyledButton from "../Components/common/Buttons";
import {useHistory} from "react-router-dom";


export default function NotFound() {
    const history = useHistory()
    return (
        <Box>
            <Header />
            <Box sx={{display:"flex", alignItems:"center", height: "95vh", mt: {xs: 5, sm: 0}, }}>
                <Grid container sx={{alignItems:"center", flexDirection:{xs:"column-reverse", md:"row"} }}>
                    <Grid
                        item
                        xs={12}
                        md={6}
                    >
                        <Box sx={{ display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            px: {xs: 5, sm: "5em", lg:"24em"}}}>
                            <Typography variant={"h3"} sx={{fontWeight: 500}}>
                                Oops! You seem to be lost.
                            </Typography>
                            <Typography sx={{mt:2, fontSize: "1em"}}>
                                Here are some helpful links:
                            </Typography>
                            <Box sx={{display: "flex", alignItems: "center", mt: 5}}>
                                <StyledButton onClick={()=>history.push('/account/register')} sx={{width: "-webkit-fill-available"}} color={"secondary"} size={"large"} variant={"contained"}>
                                    Sign up
                                </StyledButton>
                                <StyledButton onClick={()=>history.push('/account/login')} sx={{mx:2}} size={"large"} color={"secondary"} variant={"outlined"}>
                                    Login
                                </StyledButton>
                                <StyledButton  onClick={()=>history.push('/')} size={"large"} color={"secondary"}>
                                    Homepage
                                </StyledButton>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{display: "flex", flexDirection:"column", px: {xs: "5em", sm: "5em", lg:"25em"}}}>
                            <img src={fof} alt="404 not found" width={"100%"} />
                        </Box>

                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
