import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";

import StyledButton from "../../../../../../../Components/common/Buttons";
import AuthContext from "../../../../../../../Context/AuthContext";
import useAxios from "../../../../../../../utils/useAxios";
import {OtherSociety} from "./components/Form";
// import Tooltip from "@mui/material/Tooltip";
// import {Verified} from "@mui/icons-material";
// import Zoom from "@mui/material/Zoom";

const SecondarySociety= ({setLoading, userSocieties}) => {
    const { user, setToastMessage, setToastSeverity, setToastState } = React.useContext(AuthContext);
    const axios = useAxios();
    const [editable, setEditable] = React.useState(false);
    const [otherSocietyData, setOtherSocietyData]= useState({
        society_name:"",
        union_name: ""
    })
    const [otherSocietyDataError, setOtherSocietyDataError]= useState({
        society_name:"",
        union_name: ""
    })

    const dataInputChange1 = (e) => {
        setOtherSocietyData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    function handleSubmit() {
        setLoading(true)
        axios
            .post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/account/${user.user_id}/other-society/`, otherSocietyData)
            .then(function (response) {
                window.location.reload();
                setToastMessage("Other Society information added succesfully")
                setToastSeverity("success")
                setToastState(true);
                setLoading(false);
                window.location.reload()
            })
            .catch(function(error){
                error.response &&
                setToastMessage("Error! Please check your form and try agian")
                setToastSeverity("error")
                setToastState(true)
                setOtherSocietyDataError({
                    society_name:"",
                    union_name: ""
                })
                !(error.response) &&
                setToastMessage(`${error.message}`);
                setToastSeverity("error");
                setToastState(true)
                setLoading(false);
            })
    }

    return (
        <Box>
            <Box>
                <Box sx={{display:"flex", alignItems:"center"}}>
                    <Typography sx={{fontWeight:500, fontSize:"1.5em"}}>
                        Please provide information of other society that you belong to under
                        OGSCOFED Branch 1
                    </Typography>
                </Box>
                <OtherSociety otherSocietyData={otherSocietyData} editable={editable} userSocieties={userSocieties}
                    otherSocietyDataError={otherSocietyDataError} dataInputChange={dataInputChange1} />
            </Box>
            <Box>
                <StyledButton variant={"contained"} size={"large"} sx={{height: "3.7em", mt:"5em"}} onClick={handleSubmit}>
                    Submit
                </StyledButton>
            </Box>
        </Box>
    )
}

export default SecondarySociety