import {styled} from "@mui/material";
import Card from "@mui/material/Card"

const StyledCard = styled((props) => (
    <Card {...props} />
))(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    boxShadow: "none",
    border: "0.05em solid #d1d1d1",
    transition: "box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&:hover, &:focus": {
        boxShadow: "rgba(0, 0, 0, 0.15) 2px 3px 10px -2px, rgba(0, 0, 0, 0.09) 1px 2px 10px 0px, rgba(0, 0, 0, 0.07) 1px 1px 20px 0px;"
    }
}))

export default StyledCard;