import Box from "@mui/material/Box";
import React from "react";
import StyledButton from "../../Buttons";
import {useHistory} from "react-router-dom";


const Links = ({pages, handleCloseNavMenu}) => {
    const history = useHistory();
    return (
            <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' }, justifyContent: "flex-end" }}>
                {pages.map((page, index) => (
                        <StyledButton
                            key={index}
                            onClick={() => history.push(page.link)}
                            sx={{ my: 2, display: 'block', fontSize: '1em', mx: {xs: 0, md: 1} }}
                            color = {'secondary'}
                            size={"large"}
                        >
                            {page.title}
                        </StyledButton>
                    )
                )}
            </Box>
    )
}

export default Links;