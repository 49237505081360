import React from "react";
import Box from "@mui/material/Box";

import Logo from "../../../../Assets/img/logo.png";
import StyledLink from "../../Link";


const DesktopLogo = () => {
    return (

        <Box sx={{display: "flex"}}>
            <StyledLink
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                    mr: 2,
                    display: { xs: 'none', sm: 'flex' },
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                }}
            >
                    <img src={Logo} alt={"OGSCOFED Logo"} width={"20%"} />
            </StyledLink>
        </Box>
    )
}

const MobileLogo = () => {
    return (
        <StyledLink
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
                mr: 2,
                display: { xs: 'flex', sm: 'none' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
            }}
        >
            <Box sx={{display: "flex"}}>
                <img src={Logo} alt={"OGSCOFED Logo"} width={"40%"} />
            </Box>
        </StyledLink>
    )
}

export {DesktopLogo, MobileLogo};