import React from "react";
import Box from "@mui/material/Box";
import {DotLoader} from "react-spinners";


const Loader = ({loading = false}) => {
    return (
        <Box sx={{
            backgroundColor: "rgba(5, 5, 5, 0.3)",
            backdropFilter: "blur(5px)",
            zIndex: 2000,
            position: "absolute",
            width: "100%",
            height: "100%",
            bottom: 0,
            right: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <DotLoader
                color={"#39d064"} loading={loading} size={"30em"}/>
        </Box>
    )
}

export default Loader;