// import axios from "axios";
import React from "react";
import useAxios from "../../utils/useAxios";
import AuthContext from "../../Context/AuthContext";


const UserSocieties = ({user_id=null}) => {
    const axios = useAxios();
    const [userSociety, setUserSoceity] = React.useState(null);
    const { user, setToastMessage, setToastState, setToastSeverity } = React.useContext(AuthContext);
    if (!user_id) {
        user_id = user.user_id
    }
    function getUserSocieties() {
        axios
            .get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/account/${user_id}/other-society/`)
            .then(function (response) {
                setUserSoceity(response.data);
            })
            .catch(function(error) {
                !(error.response?.data) &&
                    setToastMessage(`${error.message}`);
                    setToastSeverity("error");
                    setToastState(true);
            })
    }

    React.useEffect(() => {
        getUserSocieties();
    },[])

    return userSociety
}

export default UserSocieties;