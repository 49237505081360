import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {Fade, FormControl, FormHelperText} from "@mui/material";
import React from "react";
import NaijaStates from "naija-state-local-government";

import StyledTextField from "../../../../../../../../Components/common/Inputs";
import {PhoneNumberFormat} from "../../../../../../../../HelperFunctions/PhoneNumberFormat";
import StyledButton from "../../../../../../../../Components/common/Buttons";


const Form = ({memberData, setMemberData, memberError, editable, allStates, allCities, setAllCities, setEditable, handleSubmit, setPictureChange, pictureChange}) => {
    const dataInputChange = (e) => {
        if (e.target.name === 'states') {
            setAllCities(NaijaStates.lgas(e.target.value)["lgas"])
        }
        setMemberData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const dataFilesChange = (e) => {
        setMemberData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.files[0]
        }))
        setPictureChange(true);
    }

    function openPictureDialog() {
        document.getElementById("pictureInput").click();
    }

    return (
        <Grid container spacing={5}>
            <Grid item xs={12} sm={6} lg={4}>
                <StyledTextField required label={"First name"}  sx={{mt: 2}} name={"first_name"}
                                 onChange={dataInputChange} error={!!memberError.firstNameError}
                                 helperText={!!memberError.firstNameError ? memberError.firstNameError : ""}
                                 value={memberData?.first_name} disabled={!editable}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <StyledTextField required label={"Last name"} sx={{mt: 2}} name={"last_name"}
                                 onChange={dataInputChange} error={!!memberError.lastNameError}
                                 helperText={!!memberError.lastNameError ? memberError.lastNameError : ""}
                                 value={memberData?.last_name} disabled={!editable}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <StyledTextField label={"Other name"} sx={{mt: 2}} name={"other_name"}
                                 onChange={dataInputChange} error={!!memberError.otherNameError}
                                 helperText={!!memberError.otherNameError ? memberError.otherNameError : ""}
                                 value={memberData?.other_name} disabled={!editable}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <StyledTextField required label={"Email address"} type={"email"}
                                 sx={{mt: 2}}
                                 name={"email"}
                                 onChange={dataInputChange} error={!!memberError.emailError}
                                 helperText={!!memberError.emailError ? memberError.emailError : ""}
                                 value={memberData?.email} disabled={editable}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <StyledTextField required label={"Telephone"} sx={{mt: 2}}
                                 InputProps={{
                                     inputComponent: PhoneNumberFormat,
                                 }} name={"telephone"}
                                 onChange={dataInputChange} error={!!memberError.telephoneError}
                                 helperText={!!memberError.telephoneError ? memberError.telephoneError : ""}
                                 value={memberData?.telephone} disabled={!editable}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <StyledTextField required label={"Address"} sx={{mt: 2}}
                                 onChange={dataInputChange} name={"address"}
                                 value={memberData?.address} error={!!memberError.addressError}
                                 helperText={!!memberError.addressError ? memberError.addressError : ""} disabled={!editable}/>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <StyledTextField required label={"State"} sx={{mt: 2}} select
                                 name={"states"}
                                 onChange={dataInputChange}
                                 value={memberData?.states} error={!!memberError.statesError}
                                 helperText={!!memberError.statesError ? memberError.statesError : ""} disabled={!editable}>
                    {allStates.map((state) => (
                        <MenuItem value={state} key={state}>
                            {state}
                        </MenuItem>
                    ))}
                </StyledTextField>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <StyledTextField required select label={"Local government"} sx={{mt: 2}}
                                 name={"city"}
                                 onChange={dataInputChange}
                                 value={memberData?.city} error={!!memberError.cityError}
                                 helperText={!!memberError.cityError ? memberError.cityError : ""} disabled={!editable}>
                    {allCities ? (allCities.map((city) => (
                        <MenuItem value={city} key={city}>
                            {city}
                        </MenuItem>
                    ))) : (<MenuItem disabled>Choose a state</MenuItem>)}
                </StyledTextField>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <StyledTextField required label={"Occupation"} sx={{mt: 2}}
                                 name={"occupation"}
                                 onChange={dataInputChange}
                                 value={memberData?.occupation} error={!!memberError.occupationError}
                                 helperText={!!memberError.occupationError ? memberError.occupationError : ""} disabled={!editable}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                {/*<LocalizationProvider dateAdapter={AdapterDayjs}>*/}
                {/*    <DatePicker*/}
                {/*        required label={"Date of birth"}*/}
                {/*        openTo={"year"}*/}
                {/*        views={["year", "month", "day"]}*/}
                {/*        sx={{mt: 2, width:'100%'}} name={"date_of_birth"}*/}
                {/*        onChange={(newValue) => setMemberData((prevState) => ({*/}
                {/*            ...prevState,*/}
                {/*            date_of_birth: (newValue['$d']).getDate()*/}
                {/*        }))}*/}
                {/*        format={"DD/MMMM/YYYY"}*/}
                {/*        value={memberData?.date_of_birth}*/}
                {/*        disableFuture*/}
                {/*        slotProps={{*/}
                {/*            textField: {*/}
                {/*                helperText: !!memberError.DOBError ? memberError.DOBError : "",*/}
                {/*            },*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</LocalizationProvider>*/}
                <StyledTextField required label={"Date of birth"}
                                 type={"date"}
                                 sx={{mt: 2}} name={"date_of_birth"}
                                 onChange={dataInputChange}
                                 value={memberData?.date_of_birth} error={!!memberError.DOBError}
                                 helperText={!!memberError.DOBError ? memberError.DOBError : ""} disabled={!editable}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <StyledButton size={"large"} fullWidth variant={"contained"}
                              sx={{mt: 2, height: "3.7em"}}
                              startIcon={<CloudUploadIcon/>}
                              onClick={openPictureDialog} disabled={!editable}>
                    {pictureChange
                        ? memberData?.profile_image?.name
                        : "Change profile picture"}
                </StyledButton>
                <FormControl>
                    <input type={"file"}
                           accept={"image/*"} hidden id={"pictureInput"}
                           name={"profile_image"} onChange={dataFilesChange}/>
                    <FormHelperText error={!!memberError.profilePictureError}>{!!memberError.profilePictureError ? memberError.profilePictureError : ""}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}  sx={{mt: 2}}>
                {/*{editable &&*/}
                    <Fade in={editable}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                            <StyledButton fullWidth variant={"outlined"} size={"large"} sx={{mr: 2, height: "3.7em"}} onClick={() => setEditable(false)}>
                                Cancel
                            </StyledButton>
                            </Grid>
                            <Grid item xs={6}>
                            <StyledButton fullWidth variant={"contained"} size={"large"} sx={{height: "3.7em"}} onClick={handleSubmit}>
                                Save changes
                            </StyledButton>
                            </Grid>
                        </Grid>
                    </Fade>
                {/*}*/}
            </Grid>
        </Grid>
    )
}

export default Form