import React, {useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { TablePagination, useTheme } from "@mui/material";
import { MdOutlineCancel, MdOutlineVerified } from "react-icons/md";
import dayjs from "dayjs";
// import { AccountNumberFormat } from "../../../../../HelperFunctions/PhoneNumberFormat";

import useAxios from "../../../../../utils/useAxios";
import AuthContext from "../../../../../Context/AuthContext";
import GetApplicants from "./components/GetApplicants";
import StyledButton from "../../../../../Components/common/Buttons";
import StyledTextField from "../../../../../Components/common/Inputs";
import StaffLoanApplication from "./components/StaffLoanApplication";

const Loans = ({ allMembers, sureties, setLoading }) => {
  const[state, setState]= useState(false)
  const theme = useTheme();
  const applicants = GetApplicants();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { user, setToastMessage, setToastState, setToastSeverity } =
    React.useContext(AuthContext);
  const axios = useAxios();

  const [loanApplication, setLoanApplication] = useState({
    loan_application: "",
    interest_charged: ""
})
const [loanApplicationError, setLoanApplicationError] = useState({
    loan_application: "",
    interest_charged: ""
})

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function handleLoanChange(loan_id, change) {
    let data = {};
    setLoading(true);
    if (change === "approval") {
      data = {
        loan_approved: true,
      };
    } else if (change === "disbursed") {
      data = {
        loan_disbursed: true,
      };
    } else if (change === "repaid") {
      data = {
        loan_repaid: true,
      };
    }
    axios
      .patch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/loans/${user?.user_id}/modify-loan-application/${loan_id}/`,
        data
      )
      .then(function (response) {
        change === "approval"
          ? setToastMessage("Loan approved")
          : change === "disbursed"
          ? setToastMessage("Loan disbursed")
          : setToastMessage("Loan repaid");
        setToastSeverity("success");
        setToastState(true);
        window.location.reload(true);
      })
      .catch(function (error) {
        !error.response.data && setToastMessage(`${error.message}`);
        setToastSeverity("error");
        setToastState(true);
      });
    setLoading(false);
  }

  function createLoanSchedule(loan_id) {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/loans/${user?.user_id}/loan-schedule/${loan_id}/`)
      .then(function (response) {
        setToastMessage(response.data.detail)
        setToastSeverity("success");
        setToastState(true);
        window.location.reload(true);
      })
      .catch(function (error) {
        !error.response.data && setToastMessage(`${error.message}`);
        setToastSeverity("error");
        setToastState(true);
      });
    setLoading(false);
  }
  //This data input change is for form to set interest rate
  const dataInputChange = (e) => {
    setLoanApplication((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value
    }))
    setLoanApplicationError((prevState) => ({
        ...prevState,
        [e.target.name]: ""
    }))
}
// This handle submit is for form to patch the set interest rate to the loan application
const handleInterestRateSubmit = () => {
  setLoading(true);
  let form = document.getElementById(`interestRateForm`);
  if (!form.reportValidity()) {
      setLoading(false)
      return;
  }

  setLoanApplicationError({
      loan_application: "",
      interest_charged: ""
  })
  const interestData= {"interest_charged": loanApplication.interest_charged}

  axios
  .patch(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/loans/${user?.user_id}/modify-loan-application/${loanApplication.loan_application}/`,
    interestData
  )
      .then(function(res) {
          setToastMessage("Interest Rate set")
          setToastSeverity("success")
          setToastState(true)
          setLoading(false);
          setState(false)
      })
      .catch(function(error) {
          error.response &&
          setToastMessage("An error has occured")
          setToastSeverity("error")
          setToastState(true)
          setLoanApplicationError({
            ...error.response.data
          })
          !(error.response) &&
          setToastMessage(`${error.message}`);
          setToastSeverity("error");
          setToastState(true)
          setLoading(false);
      })
  
  setLoanApplication({
    loan_application: "",
    interest_charged: ""
  })
}

  return (
    <Box>
      <Typography sx={{ fontWeight: 600, fontSize: "2em" }}>
        Loan Applicants
      </Typography>
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "1rem",
          width: "100%",
          p: "2rem",
          marginTop: "1rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Paper elevation={1} sx={{ width: "100%", overflow: "hidden", my:2, p:2}}>
          <TableContainer sx={{ maxHeight: "90vh" }}>
            <Table stickyHeader aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ color: theme.palette.primary.main, fontWeight: 600 }}
                  >
                    Coop member
                  </TableCell>
                  <TableCell
                    sx={{ color: theme.palette.primary.main, fontWeight: 600 }}
                  >
                    Amount
                  </TableCell>
                  <TableCell
                    sx={{ color: theme.palette.primary.main, fontWeight: 600 }}
                  >
                    Interest
                  </TableCell>
                  <TableCell
                    sx={{ color: theme.palette.primary.main, fontWeight: 600 }}
                  >
                    Surety one
                  </TableCell>
                  <TableCell
                    sx={{ color: theme.palette.primary.main, fontWeight: 600 }}
                  >
                    Surety two
                  </TableCell>
                  <TableCell
                    sx={{ color: theme.palette.primary.main, fontWeight: 600 }}
                    align={"center"}
                  >
                    Application date
                  </TableCell>
                  <TableCell
                    sx={{ color: theme.palette.primary.main, fontWeight: 600 }}
                    align={"center"}
                  >
                    Approved?
                  </TableCell>
                  <TableCell
                    sx={{ color: theme.palette.primary.main, fontWeight: 600 }}
                    align={"center"}
                  >
                    Date approved
                  </TableCell>
                  <TableCell
                    sx={{ color: theme.palette.primary.main, fontWeight: 600 }}
                  >
                    Loan duration
                  </TableCell>
                  <TableCell
                    sx={{ color: theme.palette.primary.main, fontWeight: 600 }}
                    align={"center"}
                  >
                    Disbursed?
                  </TableCell>
                  <TableCell
                    sx={{ color: theme.palette.primary.main, fontWeight: 600 }}
                    align={"center"}
                  >
                    Repaid?
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {applicants.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row?.coop_member}
                    </TableCell>
                    <TableCell>&#8358;{row?.loan_amount}</TableCell>
                    <TableCell>{row?.interest_charged}%</TableCell>
                    <TableCell>{row?.surety_one}</TableCell>
                    <TableCell>{row?.surety_two}</TableCell>
                    <TableCell align={"center"}>
                      {dayjs(row?.date_created).format("ddd, DD MMM YYYY")}
                    </TableCell>
                    <TableCell align={"center"}>
                      {
                        <Tooltip
                          title={
                            row?.loan_approved
                              ? "Loan approved"
                              : "Loan not approved"
                          }
                        >
                          <>
                          {row?.loan_approved ? (
                            <MdOutlineVerified
                              color={theme.palette.primary.main}
                            />
                          ) : (
                            <MdOutlineCancel color={theme.palette.error.main} />
                          )}
                          </>
                          
                        </Tooltip>
                      }{" "}
                      {!row?.loan_approved && (
                        <StyledButton
                          color="primary"
                          onClick={() => handleLoanChange(row?.id, "approval")}
                        >
                          Approve loan
                        </StyledButton>
                      )}
                    </TableCell>
                    <TableCell align={"center"}>
                      {row?.loan_approved &&
                        dayjs(row?.date_approved).format("ddd, DD MMM YYYY")}
                    </TableCell>
                    <TableCell>{row?.loan_duration} months</TableCell>
                    <TableCell align={"center"}>
                      {
                        <Tooltip
                          title={
                            row?.loan_disbursed
                              ? "Loan disbursed"
                              : "Loan not disbursed"
                          }
                        >
                          <>
                          {row?.loan_disbursed ? (
                            <MdOutlineVerified
                              color={theme.palette.primary.main}
                            />
                          ) : (
                            <MdOutlineCancel color={theme.palette.error.main} />
                          )}
                          </>
                          
                        </Tooltip>
                      }{" "}
                      {/* {!row?.loan_disbursed && (
                        <StyledButton
                          color="primary"
                          onClick={() => handleLoanChange(row?.id, "disbursed")}
                        >
                          Loan disbursed
                        </StyledButton>
                      )} */}
                    </TableCell>
                    <TableCell align={"center"}>
                      {
                        <Tooltip
                          title={
                            row?.loan_repaid ? "Loan repaid" : "Loan not repaid"
                          }
                        >
                          <>
                          {row?.loan_repaid ? (
                            <MdOutlineVerified
                              color={theme.palette.primary.main}
                            />
                          ) : (
                            <MdOutlineCancel color={theme.palette.error.main} />
                          )}
                          </>
                          
                        </Tooltip>
                      }{" "}
                      {!row?.loan_repaid && (
                        <StyledButton
                          color="primary"
                          onClick={() => handleLoanChange(row?.id, "repaid")}
                        >
                          Loan repaid
                        </StyledButton>
                      )}
                    </TableCell>
                    <TableCell>
                      {!row?.loan_disbursed && (
                        <StyledButton
                          color="primary"
                          onClick={() => createLoanSchedule(row?.id)}
                        >
                          Create Schedule
                        </StyledButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={applicants.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        <Paper elevation={1} sx={{my:2, p:2}}>
          <Typography>Loan Application</Typography>
            
            <StaffLoanApplication 
              allMembers={allMembers}
              sureties={sureties}
              setLoading={setLoading}
            />
        </Paper>

        <Paper elevation={1} sx={{my:2, p:2}}>
          <Typography>Set Interest Rate on Loan Application</Typography>
          <Box component="div">
            <Paper elevation={1} sx={{p:2, my:2}}>
              <Box component={"form"} noValidate autoComplete={"off"} id={"interestRateForm"}>
                  <StyledTextField required select label={"Select Loan Application"} sx={{mt: "2em"}}
                      name={"loan_application"} onChange={dataInputChange}
                      value={loanApplication?.loan_application} error={!!loanApplicationError.loan_application}
                      helperText={!!loanApplicationError.loan_application ? loanApplicationError.loan_application : ""}>
                      {(applicants.map((applicant, index) => (
                          <MenuItem value={`${applicant.id}`} key={index} disabled={applicant.loan_approved ? true : false}>
                              {`${applicant.coop_member} ${applicant.loan_amount}`}
                          </MenuItem>
                      )))}
                  </StyledTextField>

                  <StyledTextField required label={"Interest rate"} name={"interest_charged"} sx={{mt: "2em"}}
                    onChange={dataInputChange} error={!!loanApplicationError.interest_charged}
                    helperText={!!loanApplicationError.interest_charged ? loanApplicationError.interest_charged : ""}
                    value={loanApplication?.interest_charged}
                    // InputProps={{inputComponent: AccountNumberFormat,}}
                />

                  <StyledButton size={"large"} variant={"contained"} sx={{mt: "3em"}} onClick={handleInterestRateSubmit}>
                      Submit data
                  </StyledButton>
              </Box>
            </Paper>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default Loans;
