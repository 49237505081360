import React, {useState} from 'react'
import axios from "axios";
import AuthContext from '../../../../../Context/AuthContext';
// import IndTransactions from '../../../../../HelperFunctions/IndTransaction';
import { Box, Typography, Paper, MenuItem } from '@mui/material'
import StyledButton from '../../../../../Components/common/Buttons';
import StyledTextField from '../../../../../Components/common/Inputs';
import { TransactionsTable } from './components/TransactionsTable';
import { PostTransaction } from './components/PostTransaction';


export const Transactions = ({allMembers, setLoading}) => {
    const { user, setToastMessage, setToastSeverity, setToastState } = React.useContext(AuthContext);
    const[state, setState]= useState(false)
    const token = JSON.parse(localStorage.getItem("authTokens"))
    const aToken= token["access"]

    const [coopMember, setCoopMember] = useState({
        coop_member: "",
    })
    const [coopMemberError, setCoopMemberError] = useState({
        coop_member: "",
    })
    const [transactionData, setTransactionData]= React.useState([])

    const dataInputChange = (e) => {
        setCoopMember((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
        setCoopMemberError((prevState) => ({
            ...prevState,
            [e.target.name]: ""
        }))
    }
    const handleCoopMemberSubmit = () => {
        setLoading(true);
        let form = document.getElementById(`coopMemberForm`);
        if (!form.reportValidity()) {
            setLoading(false)
            return;
        }

        setCoopMemberError({
            coop_member: ""
        })

        axios
            .get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/transactions/${user?.user_id}/transaction/${coopMember.coop_member}/`, {
                headers: {
                    Authorization: `Bearer ${aToken}`,
                },
            })
            .then(function(res) {
                setTransactionData(res.data)
                setLoading(false);
                setState(false)
                // window.location.reload()
            })
            .catch(function(error) {
                error.response &&
                setCoopMemberError({
                    ...error.response.data
                })
        setLoading(false);
            })
    }

    return (
        <Box >
            <Typography>Manage Transactions</Typography>
            <Paper elevation={1} sx={{p:2, my:2}}>
                <Box component={"form"} noValidate autoComplete={"off"} id={"coopMemberForm"}>
                    <StyledTextField required select label={"Select Coop Member"} sx={{mt: "2em"}}
                        name={"coop_member"} onChange={dataInputChange}
                        value={coopMember?.coop_member} error={!!coopMemberError.coop_member}
                        helperText={!!coopMemberError.coop_member ? coopMemberError.coop_member : ""}>
                        {(allMembers.map((opt) => (
                            <MenuItem value={`${opt.id}`} key={opt.email}>
                                {`${opt.first_name} ${opt.last_name}`}
                            </MenuItem>
                        )))}
                    </StyledTextField>
                    <StyledButton size={"large"} variant={"contained"} sx={{mt: "3em"}} onClick={handleCoopMemberSubmit}>
                        Retrieve Records
                    </StyledButton>
                </Box>
            </Paper>

            <Paper sx={{p:2, my:2}}>
                <Typography>Member Record</Typography>
                    <TransactionsTable transactionData={transactionData} />
            </Paper>

            <Paper sx={{p:2, my:2}}>
                <Typography>Post Transaction for Member</Typography>
                <PostTransaction  
                    user={user} 
                    setLoading={setLoading}
                    setToastMessage={setToastMessage} 
                    setToastSeverity={setToastSeverity} 
                    setToastState={setToastState} 
                    coopMember={coopMember.coop_member}
                    aToken= {aToken}
                    allMembers={allMembers}
                />
            </Paper>
        </Box>
    )
}
