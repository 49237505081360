import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { green } from '@mui/material/colors';

import { AuthProvider } from "./Context/AuthContext";
import NotFound from "./Pages/notFound";
import Login from "./Pages/Auth/login"
import Registration from "./Pages/Auth/Registration/registration";
import Verification from "./Pages/Auth/verification";
import ForgotPasswordVerify from "./Pages/Auth/forgotPasswordVerify";
import ForgotPassword from "./Pages/Auth/forgotPassword";
import ChangePassword from "./Pages/Auth/changePassword";
import ReVerification from "./Pages/Auth/reVerification";
import Homepage from "./Pages/Homepage";
import Dashboard from "./Pages/Auth/Dashboard";
import StyledToastBar from "./Components/common/Toast";
import PrivateRoute from "./utils/privateRoute";
import PhoneVerification from "./Pages/Auth/phoneVerification";
import Officials from "./Pages/officials";
import AdminRoute from "./utils/AdminRoute";
import Administration from "./Pages/Auth/Administration";

let theme = createTheme({
    palette: {
        primary: {
            main: green[700],
        },
        secondary: {
            main: '#820F65',
        },
    },
    typography: {
        fontFamily: "'Merriweather Sans', sans-serif;",
    },
});

function App() {
    return (
        <Router>
            <AuthProvider>
                <ThemeProvider theme={responsiveFontSizes(theme)}>
                    <StyledToastBar />
                    <Switch>
                        {/* <Route component={AdminRoute}> */}
                        <AdminRoute path="/account/administration/members/transactions" component={Administration} ></AdminRoute>
                        <AdminRoute path="/account/administration/members/loans" component={Administration} ></AdminRoute>
                        <AdminRoute path="/account/administration/members" component={Administration} ></AdminRoute>
                        <AdminRoute path="/account/administration/dashboard" component={Administration} ></AdminRoute>
                        {/* </Route> */}
                        {/* <Route component={PrivateRoute}> */}
                        <PrivateRoute path="/account/user/dashboard/loans" component={Dashboard} ></PrivateRoute>
                        <PrivateRoute path="/account/user/dashboard/payments" component={Dashboard} ></PrivateRoute>
                        <PrivateRoute path="/account/user/dashboard/profile" component={Dashboard} ></PrivateRoute>
                        <PrivateRoute path="/account/user/dashboard" component={Dashboard} ></PrivateRoute>
                        <PrivateRoute path="/account/phone-verification" component={PhoneVerification} ></PrivateRoute>
                        {/* </Route> */}
                        
                        <Route path="/account/resend-verification" component={ReVerification} ></Route>
                        <Route path="/account/password/reset/complete/:uidb64/:token" component={ForgotPassword} ></Route>
                        <Route path="/account/password/reset" component={ForgotPasswordVerify} ></Route>
                        <Route path="/account/registration/verification/:email/:token" component={Verification} ></Route>
                        <Route path="/account/login" component={Login} ></Route>
                        <Route path="/account/register" component={Registration} ></Route>
                        <Route path="/officials" component={Officials} ></Route>
                        <Route exact path="/" component={Homepage} ></Route>
                        <Route path="*" component={NotFound} ></Route>
                    </Switch>
                </ThemeProvider>
            </AuthProvider>
        </Router>
    );
}

export default App;
