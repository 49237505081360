import React from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import html2pdf from "html2pdf.js/src";

import StyledDrawer from "../../../../../../Components/common/Drawer";
import StyledTextField from "../../../../../../Components/common/Inputs";
import {AccountNumberFormat} from "../../../../../../HelperFunctions/PhoneNumberFormat";
import StyledButton from "../../../../../../Components/common/Buttons";
import AuthContext from "../../../../../../Context/AuthContext";
import StyledAlert from "../../../../../../Components/common/Alert";
import {LoanDocument} from "./LoanDocument";


const LoanApplication = ({state, setState, sureties, setLoading, userData}) => {
    const {user, setToastMessage, setToastSeverity, setToastState} = React.useContext(AuthContext);
    const [loanData, setLoanData] = React.useState({
        loan_amount: "",
        loan_purpose: "",
        loan_duration: 24,
        surety_one: "",
        surety_two: "",
    })
    const [loanError, setLoanError] = React.useState({
        loan_amount: "",
        loan_duration: "",
        loan_purpose: "",
        surety_one: "",
        surety_two: "",
    })

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState(open);
    };
    const dataInputChange = (e) => {
        if (e.target.name === 'surety_one' && e.target.value === loanData.surety_two) {
            setLoanError((prevState) => ({
                ...prevState,
                [e.target.name]: "You have already set this member for surety_two"
            }))
            return
        }
        if (e.target.name === 'surety_two' && e.target.value === loanData.surety_one) {
            setLoanError((prevState) => ({
                ...prevState,
                [e.target.name]: "You have already set this member for surety_one"
            }))
            return
        }
        setLoanData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
        setLoanError((prevState) => ({
            ...prevState,
            [e.target.name]: ""
        }))
    }

    const handleSubmit = () => {
        setLoading(true);
        let form = document.getElementById(`loanForm`);
        if (!form.reportValidity()) {
            setLoading(false)
            return;
        }

        setLoanError({
            loan_amount: "",
            loan_purpose: "",
            surety_one: "",
            surety_two: "",
            signature: "",
            loan_duration: "",
        })
        axios
            .post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/loans/${user.user_id}/apply/`, loanData)
            .then(function(res) {
                let element = document.getElementById('loan-doc');
                html2pdf(element);
                setToastMessage('Loan application successful.\nPrint the downloaded loan document, then fill and submit');
                setToastSeverity("success");
                setToastState(true);
                setLoading(false);
                setState(false)
                // window.location.reload()
            })
            .catch(function(error) {
                error.response &&
                setToastMessage(error.response?.data?.detail ? error.response.data.detail : `You have errors in your form`);
                setToastSeverity("error");
                setToastState(true);
                setLoanError({
                    ...error.response.data
                })

                !(error.response) &&
                setToastMessage(`${error.message}`);
                setToastSeverity("error");
                setToastState(true);
        setLoading(false);
            })
    }

    return (
        <StyledDrawer
            anchor={'right'}
            open={state}
            onClose={toggleDrawer('right', false)}
            onOpen={toggleDrawer('right', true)}
            id={"loanComponent"}
        >
            <Typography sx={{fontWeight: 600, fontSize: "2em", mb: 5, textOverflow: "wrap", mt: "3em"}}>
                Apply for Loan
            </Typography>
            {loanError.detail &&
                <StyledAlert sx={{width: "100%"}} opened={loanError.status && loanError.status === 'failed'}
                             message={loanError.detail && loanError.detail}/>}
            <div style={{position: "absolute", left: "-999em", width: "210mm", height: "297mm",}}><Box id={"loan-doc"}
                sx={{width: "100%", height: "296mm", padding: "1rem"}}><LoanDocument userData={userData} loanData={loanData}/></Box></div>
            <Box component={"form"} noValidate autoComplete={"off"} id={"loanForm"}>
                <StyledTextField required label={"Loan amount"} name={"loan_amount"} sx={{mt: "2em"}}
                                 onChange={dataInputChange} error={!!loanError.loan_amount}
                                 helperText={!!loanError.loan_amount ? loanError.loan_amount : ""}
                                 value={loanData?.loan_amount}
                                 InputProps={{
                                     inputComponent: AccountNumberFormat,
                                 }}/>
                <StyledTextField required label={"Loan purpose"} name={"loan_purpose"}
                                 sx={{mt: "2em"}}
                                 onChange={dataInputChange} error={!!loanError.loan_purpose}
                                 helperText={!!loanError.loan_purpose ? loanError.loan_purpose : ""}
                                 value={loanData?.loan_purpose}/>
                <StyledTextField required select label={"Loan duration"} sx={{mt: "2em"}}
                                name={"loan_duration"} onChange={dataInputChange}
                                value={loanData?.loan_duration} error={!!loanError.loan_duration}
                                helperText={!!loanError.loan_duration ? loanError.loan_duration : ""}>
                    <MenuItem value={1}>1 month</MenuItem>
                    <MenuItem value={2}>2 months</MenuItem>
                    <MenuItem value={3}>3 months</MenuItem>
                    <MenuItem value={4}>4 months</MenuItem>
                    <MenuItem value={5}>5 months</MenuItem>
                    <MenuItem value={6}>6 months</MenuItem>
                    <MenuItem value={7}>7 months</MenuItem>
                    <MenuItem value={8}>8 months</MenuItem>
                    <MenuItem value={9}>9 months</MenuItem>
                    <MenuItem value={10}>10 months</MenuItem>
                    <MenuItem value={11}>11 months</MenuItem>
                    <MenuItem value={12}>12 months</MenuItem>
                    <MenuItem value={13}>13 months</MenuItem>
                    <MenuItem value={14}>14 months</MenuItem>
                    <MenuItem value={15}>15 months</MenuItem>
                    <MenuItem value={16}>16 months</MenuItem>
                    <MenuItem value={17}>17 months</MenuItem>
                    <MenuItem value={18}>18 months</MenuItem>
                    <MenuItem value={19}>19 months</MenuItem>
                    <MenuItem value={20}>20 months</MenuItem>
                    <MenuItem value={21}>21 months</MenuItem>
                    <MenuItem value={22}>22 months</MenuItem>
                    <MenuItem value={23}>23 months</MenuItem>
                    <MenuItem value={24}>24 months</MenuItem>
                    <MenuItem value={25}>25 months</MenuItem>
                    <MenuItem value={26}>26 months</MenuItem>
                    <MenuItem value={27}>27 months</MenuItem>
                    <MenuItem value={28}>28 months</MenuItem>
                    <MenuItem value={29}>29 months</MenuItem>
                    <MenuItem value={30}>30 months</MenuItem>
                    <MenuItem value={31}>31 months</MenuItem>
                    <MenuItem value={32}>32 months</MenuItem>
                    <MenuItem value={33}>33 months</MenuItem>
                    <MenuItem value={34}>34 months</MenuItem>
                    <MenuItem value={35}>35 months</MenuItem>
                    <MenuItem value={36}>36 months</MenuItem>
                </StyledTextField>
                <StyledTextField required select label={"Surety one"} sx={{mt: "2em"}}
                                 name={"surety_one"} onChange={dataInputChange}
                                 value={loanData?.surety_one} error={!!loanError.surety_one}
                                 helperText={!!loanError.surety_one ? loanError.surety_one : ""}>
                    {(sureties.map((opt) => (
                        <MenuItem value={`${opt.first_name} ${opt.last_name}`} key={opt.email}>
                            {`${opt.first_name} ${opt.last_name}`}
                        </MenuItem>
                    )))}
                </StyledTextField>
                <StyledTextField required select label={"Surety two"} sx={{mt: "2em"}}
                                 name={"surety_two"} onChange={dataInputChange}
                                 value={loanData?.surety_two} error={!!loanError.surety_two}
                                 helperText={!!loanError.surety_two ? loanError.surety_two : ""}>
                    {(sureties.map((opt) => (
                        <MenuItem value={`${opt.first_name} ${opt.last_name}`} key={opt.email}>
                            {`${opt.first_name} ${opt.last_name}`}
                        </MenuItem>
                    )))}
                </StyledTextField>
                <StyledButton size={"large"} variant={"contained"} sx={{mt: "3em"}} onClick={handleSubmit}>Submit
                    Application</StyledButton>
            </Box>
        </StyledDrawer>
    )
}

export default LoanApplication;