import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from '@mui/icons-material/Close';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React from "react";

import StyledLink from "../../Link";


const Mobile = ({anchorElNav, handleOpenNavMenu, handleCloseNavMenu, pages}) => {
    return (
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                    sx={{paddingLeft: 0}}
                >
                    {anchorElNav ? <CloseIcon /> : <MenuIcon/>}
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                    }}
                    PaperProps={{
                        sx: {
                            width: "80%",
                            maxWidth: "100%",
                        }
                    }}
                >
                    {pages.map((page, index) =>  (
                            <MenuItem key={index}>
                                <StyledLink href={page.link} textAlign="center">{page.title}</StyledLink>
                            </MenuItem>
                        )
                    )}
                </Menu>
            </Box>
    )
}

export default Mobile;