import React from "react";
import {useHistory, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {styled} from "@mui/material";
import OtpInput from 'react-otp-input';

import Loader from "../../Components/common/Loader";
import StyledLink from "../../Components/common/Link";
import StyledButton from "../../Components/common/Buttons";
import StyledCard from "../../Components/common/Card";
import logo from "../../Assets/img/logo.png";
import axios from "axios";
import StyledAlert from "../../Components/common/Alert";
import Header from "../../Components/common/Header";
import AuthContext from "../../Context/AuthContext";


const StyledInput = styled("input")(({theme}) => ({
    width: "2em!important",
    height: "2.5em",
    fontSize: "1em",
    color: theme.palette.secondary.main,
    borderRadius: "0.4em",
    border: `3px solid ${theme.palette.primary.main}`,
    // borderColor: theme.palette.primary.main,
    "&:focus-visible": {
        outline: "none"
    },
    margin: "0.5em"
}));


const Verification = () => {
    const history = useHistory();
    const {setToastMessage, setToastState, setToastSeverity} = React.useContext(AuthContext)
    const [loading, setLoading] = React.useState(false);
    const {token, email} = useParams();

    const [otp, setOtp] = React.useState('');
    const [message, setMessage] = React.useState(null)
    // setOtp(token);

    const handleSubmit = () => {let form = document.getElementById(`form`);
        if (!form.reportValidity()) {
            return;
        }
        setLoading(true);
        axios
            .get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/account/email-verify/${otp}/`)
            .then(function (res) {
                setLoading(false);
                history.push(`/account/login`);
                setToastMessage('Verification successful');
                setToastSeverity("success");
                setToastState(true);
            })
            .catch(function (error) {
                if (error.response.data['detail'] === "Activation Expired") {
                    axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/account/resend-verification/`, {email: email})
                        .then(function (res) {
                            setMessage(`${error.response.data['detail']}. ${res.data['detail']}`)
                        })
                        .catch(function (err) {
                            setMessage(err.response.data['detail'])
                        })
                } else {
                    setMessage(<StyledLink href={"/account/resend-verification"}>{error.response.data['detail']}. Request for a new verification here</StyledLink>)
                }
                setLoading(false)
            })
    }

    React.useEffect(() => {
        setOtp(token);
    }, [otp, token])
    return (
        <>
            {loading && <Loader loading={loading}/>}
            <Header />
            <Box sx={{height: "95vh", mt: {xs: 3, sm: 0}, width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap"}}>
                <Box sx={{
                    width: {xs: "90%", sm: "50%", md: "30%", lg: "20%"},
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center"
                }}>
                    <StyledCard variant={"outlined"} sx={{p: 3}}>
                        <Box
                            sx={{
                                fontWeight: 900,
                                textAlign: "center",
                                width: "20%",
                                display: "flex",
                                alignSelf: "center"
                            }}>
                            <img src={logo} alt={"OGSCOFED logo"} width={"100%"}/>
                        </Box>
                        <Typography sx={{fontWeight: 500, mt: 3, textAlign: "center"}}>
                            Enter verification code in the space provided below:
                        </Typography>
                        {message && <StyledAlert opened={true} message={message} />}

                        <Box component="form" noValidate autoComplete="off" id={"form"} sx={{mt:3}}>
                            <OtpInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={40}
                                disabled
                                // renderSeparator={<span>-</span>}
                                renderInput={(props) => <StyledInput {...props} />}
                                containerStyle={{justifyContent: "center", flexWrap: "wrap"}}
                            />

                        </Box>
                        <StyledButton variant={"contained"} size={"large"} sx={{mt:2}} onClick={handleSubmit} >
                            Verify Account
                        </StyledButton>
                        <Typography sx={{mt:10, textAlign: "center", fontWeight: 600}}>
                            Already have an account?
                        </Typography>
                        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", mt: 2}}>
                            <StyledButton variant={"text"} size={"large"} sx={{ml: "-8px"}}
                                          onClick={() => history.push("/account/register")}>
                                Create account
                            </StyledButton>
                            <StyledButton variant={"contained"} size={"large"} onClick={() => history.push("/account/login")}>
                                Login
                            </StyledButton>
                        </Box>
                    </StyledCard>
                </Box>
            </Box>
        </>
    )
}

export default Verification;