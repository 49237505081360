import Box from "@mui/material/Box";
import Header from "../Components/common/Header";
import React from "react";
import Card from "@mui/material/Card";
import {styled} from "@mui/material";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import AuthContext from "../Context/AuthContext";
import Skeleton from "@mui/material/Skeleton";


const OfficialCard = styled((props) => (
    <Card {...props} />
))(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    borderRadius: "1.5em",
    boxShadow: "5px 5px 20px 0 rgba(0,0,0,0.2)",
    border: "none",
    transition: "box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    height:"40vh",
    width:"100%",
    maxWidth: "350px"
    // padding: "2em 0.5em"
}))


const Officials = () => {
    const [officialsData, setOfficialsData] = React.useState([]);
    const { setToastMessage, setToastState, setToastSeverity } = React.useContext(AuthContext);

    function getOfficials() {
        axios
            .get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/officers/`)
            .then(function (res) {
                setOfficialsData(res.data);
            })
            .catch(function (err) {
                setToastMessage(`${err.message}`);
                setToastSeverity("error");
                setToastState(true);
            })
    }

    React.useEffect(() => {
        getOfficials();
    },[])

    return (
        <Box>
            <Header />
            <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", height: {xs:"100%"}, my: {xs: 10, sm:25, lg: 20}, }}>
                <Box sx={{display:"flex", flexDirection:"column",textAlign:"center",
                    width:{xs:"100%", md:"80%", lg:"80%"} }}>
                        <Typography variant={"h2"} color={"secondary"} sx={{mb:"0.25em"}}>Meet Our Officials</Typography>
                        {/*{officialsData.length===0 && <Typography variant={"h6"} sx={{mb: "0.25em"}}>No Officials data</Typography>}*/}
                    <Box sx={{display:"flex", gap:"3em", alignItems:"center", justifyContent:"center", flexWrap:"wrap"}}>
                        {officialsData && officialsData.map((data) => (
                            <OfficialCard>
                                <Box sx={{height:"100%", p:"3em 1.5em", display:"flex", flexDirection:"column", alignItems:"center", textAlign:"center"}}>
                                    <Avatar src={`${process.env.REACT_APP_BACKEND_ENDPOINT}${data?.profile_image}`} alt={`${data?.coop_member?.first_name} ${data?.coop_member?.last_name}`} sx={{width:"10em", height:"10em", border:"5px solid #820F65"}} />
                                    <Typography variant={"h5"} sx={{my:6}}>{data?.coop_member?.first_name} {data?.coop_member?.first_name}</Typography>
                                    <Typography>
                                        <em>
                                            {data?.bio}
                                        </em>
                                    </Typography>
                                    <Typography variant={"h5"} color={"secondary"} sx={{fonWeight:600, mt:"auto"}}>{data.position}</Typography>
                                </Box>
                            </OfficialCard>
                            ))
                        }
                        {officialsData.length===0 &&
                            <>
                                <OfficialCard>
                                    <Box sx={{
                                        height: "100%",
                                        p: "3em 1.5em",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        textAlign: "center"
                                    }}>
                                        <Skeleton variant={"circular"} sx={{width: "10em", height: "10em", border: "5px solid #820F65"}} />

                                        <Typography variant={"h5"} sx={{my: 6, width:"100%"}}><Skeleton variant={"text"}/></Typography>
                                        <Typography sx={{width:"100%"}}>
                                            <em>
                                                <Skeleton />
                                                <Skeleton />
                                                <Skeleton />
                                                <Skeleton width="60%" />
                                            </em>
                                        </Typography>
                                        <Typography variant={"h5"} color={"secondary"} sx={{fonWeight: 600, mt: "auto", width: "100%"}}>
                                            <Skeleton variant={"text"}/>
                                        </Typography>
                                    </Box>
                                </OfficialCard>
                                <OfficialCard>
                                    <Box sx={{
                                        height: "100%",
                                        p: "3em 1.5em",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        textAlign: "center"
                                    }}>
                                        <Skeleton variant={"circular"} sx={{width: "10em", height: "10em", border: "5px solid #820F65"}} />

                                        <Typography variant={"h5"} sx={{my: 6, width:"100%"}}><Skeleton variant={"text"}/></Typography>
                                        <Typography sx={{width:"100%"}}>
                                            <em>
                                                <Skeleton />
                                                <Skeleton />
                                                <Skeleton />
                                                <Skeleton width="60%" />
                                            </em>
                                        </Typography>
                                        <Typography variant={"h5"} color={"secondary"} sx={{fonWeight: 600, mt: "auto", width: "100%"}}>
                                            <Skeleton variant={"text"}/>
                                        </Typography>
                                    </Box>
                                </OfficialCard>
                            </>
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Officials;