import Grid from "@mui/material/Grid";
import StyledTextField from "../../../../Components/common/Inputs";
import Box from "@mui/material/Box";
import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import StyledButton from "../../../../Components/common/Buttons";


const Form4 = ({dataInputChange, memberData, memberError, handleBack, handleSubmit, loading}) => {

    const [showPassword, setShowPassword] = React.useState(false);
    const [showPassword2, setShowPassword2] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return (
        <Box component="form" noValidate autoComplete="off" id={"form4"}>
            <Grid container sx={{mt: 3}} spacing={1}>
                <Grid item xs={12}>
                    <StyledTextField
                        required
                        label={"Enter password"} sx={{mt: 2}}
                        type={showPassword ? 'text' : 'password'}
                        name={"password"}
                        onChange={dataInputChange}
                        value={memberData?.password}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff/> :
                                            <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                        }}
                        error={!!memberError.passwordError}
                        helperText={!!memberError.passwordError ? memberError.passwordError : ""}
                    />
                </Grid>
                <Grid item xs={12}>
                    <StyledTextField
                        required
                        label={"Confirm password"} sx={{mt: 2}}
                        type={showPassword2 ? 'text' : 'password'}
                        name={"password2"}
                        onChange={dataInputChange}
                        value={memberData?.password2}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword2}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword2 ? <VisibilityOff/> :
                                            <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                        }}
                        error={!!memberError.password2Error}
                        helperText={!!memberError.password2Error ? memberError.password2Error : ""}
                    />
                </Grid>
            </Grid>
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 10
            }}>
                <StyledButton variant={"text"} size={"large"} sx={{ml: "-8px"}}
                              onClick={handleBack}  disabled={loading}>
                    Back
                </StyledButton>
                <StyledButton disabled={loading} variant={"contained"}
                              size={"large"} onClick={handleSubmit}>
                    Finish
                </StyledButton>
            </Box>
        </Box>
    )
}

export default Form4;