import {
    Route, Redirect
} from 'react-router-dom';
import React from "react";
import AuthContext from "../Context/AuthContext";

const PrivateRoute = ({redirectPath = '/account/login', children, ...rest }) => {
    const { user } = React.useContext(AuthContext);

    return <Route {...rest}>{!user ? <Redirect to="/account/login" /> : children}</Route>;
};

export default  PrivateRoute