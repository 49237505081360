import {
    Route, Redirect, useHistory
} from 'react-router-dom';
import React from "react";
import AuthContext from "../Context/AuthContext";
import UserData from "../HelperFunctions/UserData";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import StyledButton from "../Components/common/Buttons";

const AdminRoute = ({redirectPath = '/account/login', children, ...rest }) => {
    const history = useHistory()
    const { user } = React.useContext(AuthContext);
    const userData = UserData()

    
    return <Route {...rest}>{!user && !userData?.is_staff ? <Redirect to="/account/login" /> : children}</Route>;

    // if (!user) {
    //     return <Route {...rest}><Redirect to={redirectPath} /></Route>;
    // }

    // if (!userData?.is_staff) {
    //     return (
    //         <Box sx={{width:"100vw", height:"100vh", display:"flex", alignItems:"center", justifyContent:"center"}}>
    //             <Typography>
    //                 Logged in account is not a staff account. Log out and
    //                 <StyledButton  onClick={()=>history.push('/account/login')} variant={'text'}>Log in</StyledButton>
    //                 again with a staff account
    //             </Typography>
    //         </Box>
    //     )
    // }

    // return <Route>{children}</Route>;
};

export default  AdminRoute