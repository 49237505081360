import React from "react";
import AuthContext from "../../../../../../Context/AuthContext";
import useAxios from "../../../../../../utils/useAxios";

const GetReferees = () => {
    const [userData, setUserData] = React.useState([]);
    const { user, setToastMessage, setToastState, setToastSeverity } = React.useContext(AuthContext);
    const axios = useAxios();

    function getUserData () {
        axios
            .get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/account/staff/${user?.user_id}/all-referees/`)
            .then(function (response) {
                setUserData(response.data);
            })
            .catch(function(error) {
                !(error.response.data) &&
                setToastMessage(`${error.message}`);
                setToastSeverity("error");
                setToastState(true);
            })
    }

    React.useEffect(() => {
        getUserData();
    },[])

    return userData
}

export default GetReferees;