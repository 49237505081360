import axios from "axios";
import React from "react";
import AuthContext from "../../Context/AuthContext";


const UserTransactions = () => {
    const [userTransaction, setUserTransaction] = React.useState(null);
    const { user, setToastMessage, setToastState, setToastSeverity } = React.useContext(AuthContext);
    function getUserData() {
        axios
            .get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/transactions/${user.user_id}/transaction/`)
            .then(function (response) {
                setUserTransaction(response.data)
            })
            .catch(function(error) {
                !(error.response?.data) &&
                    setToastMessage(`${error.message}`);
                    setToastSeverity("error");
                    setToastState(true);
            })
    }

    React.useEffect(() => {
        getUserData();
    },[])
    return userTransaction
}

export default UserTransactions;