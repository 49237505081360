import React from "react";
import {IMaskInput} from 'react-imask';
import PropTypes from "prop-types";

const PhoneNumberFormat = React.forwardRef(function PhoneNumberFormat(
    props,
    ref
) {
    const {onChange, ...other} = props;

    return (
        <IMaskInput
            {...other}
            mask="+2340000000000"
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({target: {name: props.name, value}})}
            overwrite
        />
    );
});

PhoneNumberFormat.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const AccountNumberFormat = React.forwardRef(function PhoneNumberFormat(
    props,
    ref
) {
    const {onChange, ...other} = props;

    return (
        <IMaskInput
            {...other}
            mask="000000000000"
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({target: {name: props.name, value}})}
            overwrite
        />
    );
});

AccountNumberFormat.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};


const AmountNumberFormat = React.forwardRef(function PhoneNumberFormat(
    props,
    ref
) {
    const {onChange, ...other} = props;

    return (
        <IMaskInput
            {...other}
            mask="000000000000"
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({target: {name: props.name, value}})}
            overwrite
        />
    );
});

AmountNumberFormat.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export {PhoneNumberFormat, AccountNumberFormat, AmountNumberFormat};