import {useLocation, useHistory, useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeOutlined from "@mui/icons-material/HomeOutlined";
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import PaymentsOutlined from "@mui/icons-material/PaymentsOutlined";
import CreditScoreOutlined from "@mui/icons-material/CreditScoreOutlined";
import {green, red} from "@mui/material/colors";
import MuiDrawer from '@mui/material/Drawer';
import Drawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {styled} from "@mui/material";
import IconButton from "@mui/material/IconButton";

import Logo from "../../../../../Assets/img/logo.png"
import StyledLink from "../../../../../Components/common/Link";
import {Logout} from "@mui/icons-material";
import React from "react";
import AuthContext from "../../../../../Context/AuthContext";
import Tooltip from "@mui/material/Tooltip";
import Zoom from '@mui/material/Zoom';


const StyledListItemText = styled((props) => (
    <ListItemText {...props} />
))(({theme}) => ({
    "& .MuiTypography-root": {
        fontSize:"1.15em",
        fontWeight:500
    }
}))



const drawerWidth = 480;

const openedMixin = (theme) => ({
    // width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    [theme.breakpoints.up('sm')]: {
        width: 200
    },
    [theme.breakpoints.up('md')]: {
        width: 300
    },
    [theme.breakpoints.up('xl')]: {
        width: drawerWidth
    },
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 30px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 25px)`,
    },
});

const DrawerLarge = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


const items = [
    {title: "Home", icon: <HomeOutlined />, link: "/account/user/dashboard"},
    {title: "Payments", icon: <PaymentsOutlined />, link: "/account/user/dashboard/payments"},
    {title: "Loans", icon: <CreditScoreOutlined />, link: "/account/user/dashboard/loans"},
    {title: "Profile", icon: <AccountBoxOutlinedIcon />, link: "/account/user/dashboard/profile"},
]

const Sidebar = ({handleDrawerClose, drawerOpen, DrawerHeader, mobileOpen, handleMobileDrawerOpen}) => {
    const location = useLocation();
    const history = useHistory();
    const { logoutUser } = React.useContext(AuthContext);
    function handleLink(link) {
        history.push(link);
    }
    return (
        <>
            <DrawerLarge variant="permanent" open={drawerOpen} sx={{display: {xs:"none", sm:"flex"}}}>
                <DrawerHeader sx={{justifyContent:"space-between", alignItems:"center"}}>
                    <Box sx={{px:"2em", width: {xs:"100%",sm:"80%", md:"60%", xl:"40%"}, display:"flex", alignItems:"cenetr"}}>
                        <StyledLink href={"/"}>
                            <img src={Logo} alt={"OGSCOFED logo"} width={"100%"} loading={"lazy"} />
                        </StyledLink>
                    </Box>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon color={'secondary'} />
                    </IconButton>
                </DrawerHeader>
                {/*<Divider />*/}
                <List sx={{maxHeight:"80%"}}>
                    {items.map((item, index) => (
                        <ListItem
                            sx={{color: location.pathname===item.link ? '#fff' : 'inherit' }}
                            key={index}>
                            <ListItemButton sx={{backgroundColor: location.pathname===item.link ? `${green[700]}!important` : "transparent",
                                padding: "1em 1em", borderRadius: "0.6em"}} onClick={() => handleLink(item.link)}>
                                <ListItemIcon sx={{color: location.pathname===item.link ? '#fff' : 'inherit'}} >
                                    <Tooltip title={item.title} TransitionComponent={Zoom}>
                                        {item.icon}
                                    </Tooltip>
                                </ListItemIcon>
                                <StyledListItemText primary={item.title} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <List sx={{marginTop:"auto"}}>
                    <ListItem
                        sx={{color:'#fff'}}>
                        <ListItemButton sx={{backgroundColor: red[700],
                            padding: "1em 1em", borderRadius: "0.6em", "&:hover":{backgroundColor: red[900]} }} onClick={logoutUser}>
                            <ListItemIcon sx={{color:'#fff'}} >
                                <Logout />
                            </ListItemIcon>
                            <StyledListItemText primary={"Logout"} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </DrawerLarge>
            <Drawer
                // container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleMobileDrawerOpen}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' }, zIndex:1202,
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
                }}
            >
                <DrawerHeader>
                    <Box sx={{px:"2em"}}>
                        <StyledLink href={"/"}>
                            <img src={Logo} alt={"OGSCOFED logo"} width={"30%"} loading={"lazy"} />
                        </StyledLink>
                    </Box>
                </DrawerHeader>
                <List sx={{maxHeight:"80%"}}>
                    {items.map((item, index) => (
                        <ListItem
                            sx={{color: location.pathname===item.link ? '#fff' : 'inherit' }}
                            key={index}>
                            <ListItemButton sx={{backgroundColor: location.pathname===item.link ? `${green[700]}!important` : "transparent",
                                padding: "1em 1em", borderRadius: "0.6em"}} onClick={() => handleLink(item.link)}>
                                <ListItemIcon sx={{color: location.pathname===item.link ? '#fff' : 'inherit'}} >
                                    {item.icon}
                                </ListItemIcon>
                                <StyledListItemText primary={item.title} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <List sx={{marginTop:"auto"}}>
                <ListItem
                    sx={{color:'#fff'}}>
                    <ListItemButton sx={{backgroundColor: red[700],
                        padding: "1em 1em", borderRadius: "0.6em", "&:hover":{backgroundColor: red[900]} }} onClick={logoutUser}>
                        <ListItemIcon sx={{color:'#fff'}} >
                            <Logout />
                        </ListItemIcon>
                        <StyledListItemText primary={"Logout"} />
                    </ListItemButton>
                </ListItem>
            </List>
            </Drawer>
        </>
    )
}

export default Sidebar;