import React from "react";
import Box from "@mui/material/Box";
import {useLocation, useHistory} from "react-router-dom";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import StyledLink from "../../Components/common/Link";
import StyledButton from "../../Components/common/Buttons";
import StyledCard from "../../Components/common/Card";
import logo from "../../Assets/img/logo.png";
import StyledTextField from "../../Components/common/Inputs";
import Loader from "../../Components/common/Loader";
import AuthContext from "../../Context/AuthContext";
import StyledAlert from "../../Components/common/Alert";
import Header from "../../Components/common/Header";


const Login = () => {
    const history = useHistory();
    const location = useLocation();
    let from = location.state?.from?.pathname;
    const { loginUser } = React.useContext(AuthContext);
    const [loading, setLoading] = React.useState(false);

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [memberData, setMemberData] = React.useState({
        email: "",
        password: "",
        telephone: "",
    })

    const [memberError, setMemberError] = React.useState({
        error: false,
        errorMessage: "",
        emailError: "",
        telephoneError: "",
        passwordError: "",
    })

    const dataInputChange = (e) => {
        setMemberData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = async () => {
        setMemberError({
            error: false,
            errorMessage: "",
            emailError: "",
            telephoneError: "",
            passwordError: "",
        })
        await loginUser(memberData, setMemberError, setLoading, from);
    }

    React.useEffect(() => {
        memberData.email[0]==='0' && setMemberData((prevState)=>({...prevState,telephone: `+234${(memberData.email).slice(1)}`}))
        memberData.email[0]==='+' && setMemberData((prevState)=>({...prevState,telephone: `${memberData.email}`}))
    }, [memberData])
    return (
        <>
            {loading && <Loader loading={loading}/>}
            <Header />
            <Box sx={{height: "95vh", mt: {xs: 3, sm: 0}, width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap"}}>
                <Box sx={{
                    width: {xs: "90%", sm: "50%", md: "30%", lg: "20%"},
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center"
                }}>
                    <StyledCard variant={"outlined"} sx={{p: 3}}>
                        <Box sx={{
                            fontWeight: 900,
                            textAlign: "center",
                            width: "20%",
                            display: "flex",
                            alignSelf: "center"
                        }}>
                            <img src={logo} alt={"OGSCOFED logo"} width={"100%"}/>
                        </Box>
                        <Typography sx={{fontWeight: 600, mt: 3, textAlign: "center"}}>
                            Sign in <br/><span style={{fontWeight: 400}}>to continue to OGSCOFED HOUSING</span>
                        </Typography>
                        {memberError.error && <StyledAlert opened={memberError.error} message={memberError.errorMessage && memberError.errorMessage} />}
                        <Box component="form" noValidate autoComplete="off" id={"form"}>
                            <StyledTextField required label={"Email address or Phone number"} type={"text"}
                                             sx={{mt: 2}} name={"email"} placeholder={"user@example.com or 0xxxxxxxxx"}
                                             onChange={dataInputChange} error={!!memberError.emailError}
                                             helperText={!!memberError.emailError ? memberError.emailError : ""}
                                             value={memberData?.email}/>
                            <StyledTextField
                                required
                                label={"Enter password"} sx={{mt: 2, mb: 1}}
                                type={showPassword ? 'text' : 'password'}
                                name={"password"}
                                onChange={dataInputChange}
                                value={memberData?.password}
                                error={!!memberError.passwordError}
                                helperText={!!memberError.passwordError ? memberError.passwordError : ""}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                            <StyledLink href={"/account/password/reset"}>
                                Forgot password?
                            </StyledLink>
                            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", mt: 10}}>
                                <StyledButton variant={"text"} size={"large"} sx={{ml: "-8px"}}
                                              onClick={() => history.push("/account/register")}>
                                    Create account
                                </StyledButton>
                                <StyledButton variant={"contained"} size={"large"} onClick={handleSubmit}>
                                    Login
                                </StyledButton>
                            </Box>
                        </Box>

                    </StyledCard>
                </Box>
            </Box>
        </>
    )
}

export default Login;