import React from "react";
import {useHistory} from "react-router-dom";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from '@mui/icons-material/Menu';
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Badge from "@mui/material/Badge";
import Notifications from "@mui/icons-material/Notifications";
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';

import CreateAccount from "./components/createAccount";
import MakePayment from "./components/makePayment";
import BankDetails from "./components/bankDetails";
import StyledButton from "../../../../../Components/common/Buttons";
import StyledLink from "../../../../../Components/common/Link";
import AuthContext from "../../../../../Context/AuthContext";
import FundWallet from "./components/fundWallet";


const settings = [
    {id:0, title:'Home', link:'/'},
    {id:1, title:'Dashboard', link:'/account/user/dashboard'},
    {id:2, title:'Profile', link:'/account/user/dashboard/profile'},
    {id:3, title:'Payments', link:'/account/user/dashboard/payments'},
    {id:4, title:'Loans', link:'/account/user/dashboard/loans'},
    {id:5, title:'Administration', link:'/account/administration/dashboard'},
    {id:6, title:'Logout', link:''}
];

const drawerWidth = 480;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    paddingTop: "1em",
    paddingBottom: "1em",
    boxShadow: "none",
    borderBottom: "1px solid #d1d1d1",
    backgroundColor: "#fff",
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        [theme.breakpoints.up('sm')]: {
            marginLeft: 200,
            width: `calc(100% - ${200}px)`,
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: 300,
            width: `calc(100% - ${300}px)`,
        },
        [theme.breakpoints.up('xl')]: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
        },
        // marginLeft: drawerWidth,
        // width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Header = ({setLoading, userData, userWallet, userReferee, userBank, drawerOpen, handleDrawerOpen, handleMobileDrawerOpen}) => {
    const [state, setState] = React.useState(false)
    const [paymentState, setPaymentState] = React.useState(false);
    const [bankState, setBankState] = React.useState(false);
    const [walletState, setWalletState] = React.useState(false);
    const history = useHistory();
    const { logoutUser } = React.useContext(AuthContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    function handleLink(link) {
        history.push(link);
    }

    return (
        <AppBar position="fixed" open={drawerOpen}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                        marginRight: 5,
                        display: {xs:'none', sm:'flex'},
                        ...(drawerOpen && { display: 'none' }),
                    }}
                >
                    <MenuIcon color={"secondary"} />
                </IconButton>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleMobileDrawerOpen}
                    edge="start"
                    sx={{
                        display: {xs:'flex', sm:'none'},
                        // ...(drawerOpen && { display: 'none' }),
                    }}
                >
                    <MenuIcon color={"secondary"} />
                </IconButton>
                    { state && <CreateAccount state={state} setState={setState} setLoading={setLoading} /> }
                    { paymentState && <MakePayment state={paymentState} setState={setPaymentState} setLoading={setLoading} userData={userData} /> }
                    { bankState && <BankDetails state={bankState} setState={setBankState} setLoading={setLoading} /> }
                    { walletState && <FundWallet state={walletState} setState={setWalletState} setLoading={setLoading} /> }
                    {userData?.profile_image && userWallet?.account_number==='' && userReferee?.length > 1 &&
                        <StyledButton size={"large"} variant={"contained"} color={"secondary"} sx={{marginX: {xs:0, sm:1, md:2}, fontSize:{xs:"0.5em", sm:"0.75em", md:"1em"} }} onClick={() => setState(true)}>
                            Create Account
                        </StyledButton>}
                    {userData?.profile_image && userWallet?.account_number!=='' && userReferee?.length > 1 &&
                        <StyledButton size={"large"} variant={"contained"} color={"secondary"} sx={{marginX: {xs:0, sm:1, md:2}, fontSize:{xs:"0.5em", sm:"0.75em", md:"1em"} }} onClick={() => setPaymentState(true)}>
                            Make Payment
                        </StyledButton>}
                    {/*{userData?.profile_image && userWallet?.account_number!=='' && userReferee?.length > 1 &&*/}
                    {/*    <StyledButton size={"large"} variant={"contained"} color={"secondary"} sx={{marginX: {xs:0, sm:1, md:2}, fontSize:{xs:"0.5em", sm:"0.75em", md:"1em"} }} disabled onClick={() => setWalletState(true)}>*/}
                    {/*        Fund Wallet*/}
                    {/*    </StyledButton>}*/}
                    {userData?.profile_image && !userBank && userReferee?.length > 1 &&
                        <StyledButton size={"large"} variant={"contained"} color={"secondary"} sx={{marginX: {xs:0, sm:1, md:2}, fontSize:{xs:"0.5em", sm:"0.75em", md:"1em"} }} onClick={() => setBankState(true)}>
                            Add Bank Details
                        </StyledButton>}
                    <Box sx={{display:"flex", justifyContent: "flex-end", alignItems: "center", marginLeft:"auto" }}>
                        <IconButton color={"primary"}>
                            <Badge sx={{'& .MuiBadge-badge': {border: `1px solid #edffee`,}}} variant={"dot"} color={"primary"}>
                                <Notifications sx={{color: "#000"}} />
                            </Badge>
                        </IconButton>
                        <Box>
                            <Tooltip title="Account settings">
                                <>
                                <StyledButton
                                    onClick={handleClick}
                                    size="medium"
                                    sx={{ ml: 2, fontWeight:500, color:"black", textTransform:"capitalize", display:{xs:"none", md:"flex"} }}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    <Avatar src={`${process.env.REACT_APP_BACKEND_ENDPOINT}${userData?.profile_image}`} sx={{ width:36, height:36, mr:1, backgroundColor:"#000" }} />
                                    {userData?.first_name} {userData?.last_name}
                                    {open ? <KeyboardArrowDownIcon /> : <ChevronRight />}
                                </StyledButton>
                                <StyledButton
                                    onClick={handleClick}
                                    size="medium"
                                    sx={{ ml: 2, fontWeight:500, color:"black", textTransform:"capitalize", display:{xs:"flex", md:"none"} }}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    <Avatar src={`${process.env.REACT_APP_BACKEND_ENDPOINT}${userData?.profile_image}`} sx={{ width:36, height:36, mr:1, backgroundColor:"#000" }} />
                                    {open ? <KeyboardArrowDownIcon /> : <ChevronRight />}
                                </StyledButton>
                                </>
                            </Tooltip>
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            backgroundColor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                {settings.filter(setting => {
                                    return !(!userData?.is_staff && setting.id === 5);
                                }).map(setting => (
                                    <MenuItem key={setting.id} onClick={() => setting.title==='Logout' ? logoutUser() : handleLink(setting.link)}>
                                        <StyledLink color={setting.title==='Logout' ? 'error' : "primary"} textAlign="center">{setting.title}</StyledLink>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </Box>
            </Toolbar>
        </AppBar>
    )
}

export default Header