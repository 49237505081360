import axios from "axios";
import React from "react";
import AuthContext from "../../Context/AuthContext";


const UserBank = () => {
    const [userBank, setUserBank] = React.useState(null);
    const { user, setToastMessage, setToastState, setToastSeverity } = React.useContext(AuthContext);
    function getUserData() {
        axios
            .get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/account/${user.user_id}/bank-details/`)
            .then(function (response) {
                setUserBank(response.data);
            })
            .catch(function(error) {
                setToastMessage(`Provide bank details`);
                setToastSeverity("error");
                    setToastState(true);
                    
                //I could not get the toast to display the error message so i wrote it manually
                // console.log(`${error.response?.data}`)
                // !(error.response?.data) &&
                //     setToastMessage(`${error.message}`);
                //     setToastSeverity("error");
                //     setToastState(true);
            })
    }

    React.useEffect(() => {
        getUserData();
    },[])

    return userBank
}

export default UserBank;