import React from "react";
import Box from "@mui/material/Box";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import MyProfile from "./components/profile/Profile";
import {styled} from "@mui/material";
import MyReferees from "./components/referees/Referees";
import SecondarySociety from "./components/society/Society";



const StyledTab = styled((props) => (
    <Tab {...props} />
))(({theme}) => ({
    "&.Mui-selected": {
        color: theme.palette.secondary.main,
        fontWeight:600
    }
}))


const Profile = ({setLoading, userReferee, userSocieties}) => {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box sx={{display:"flex", flexDirection: "column"}}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value} indicatorColor="secondary">
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', m:"2em" }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example" indicatorColor="secondary">
                            <StyledTab label="Personal information" value="1" />
                            <StyledTab label="Referees" value="2" />
                            <StyledTab label="Other Society" value="3"/>
                        </TabList>
                    </Box>
                    <TabPanel value="1"><MyProfile setLoading={setLoading} /></TabPanel>
                    <TabPanel value="2"><MyReferees setLoading={setLoading} userReferee={userReferee} /></TabPanel>
                    <TabPanel value="3"><SecondarySociety setLoading={setLoading} userSocieties={userSocieties}/></TabPanel>
                </TabContext>
            </Box>
        </Box>
    )
}

export default Profile