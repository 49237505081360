import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {TablePagination, useTheme} from "@mui/material";

import {Row, createData} from "./components/DataTable";

// const rows = [
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
//     createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
//     createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
//     createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
// ];

const Members = ({allMembers, allReferees, setLoading}) => {
    const theme = useTheme()
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const rows = allMembers?.map((member)=>(
            createData(member?.id, `${member?.first_name} ${member?.last_name}`,
                member?.email, member?.telephone, member?.is_active, member?.wallet_balance,
                member?.date_of_birth, member?.date_joined, member?.telephone_verified, allReferees)
        ))

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Box>
            <Typography sx={{fontWeight:600, fontSize:"2em"}}>
                All Members
            </Typography>
            <Box sx={{ backgroundColor:"#fff",
                borderRadius:"1rem", width:"100%", p:"2rem",
                marginTop:"1rem", display:"flex", flexDirection:"column",
                justifyContent:"space-between"}}>
                    <Paper elevation={0} sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight:"90vh" }}>
                        <Table stickyHeader aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell sx={{color:theme.palette.primary.main, fontWeight:600}}>Full Name</TableCell>
                                    <TableCell sx={{color:theme.palette.primary.main, fontWeight:600}}>Email</TableCell>
                                    <TableCell sx={{color:theme.palette.primary.main, fontWeight:600}}>Telephone</TableCell>
                                    <TableCell sx={{color:theme.palette.primary.main, fontWeight:600}} align={"center"}>Verified</TableCell>
                                    <TableCell sx={{color:theme.palette.primary.main, fontWeight:600}}>Wallet balance</TableCell>
                                    <TableCell sx={{color:theme.palette.primary.main, fontWeight:600}}>Date of Birth</TableCell>
                                    <TableCell sx={{color:theme.palette.primary.main, fontWeight:600}}>Date Joined</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <Row key={row.user_id} row={row} setLoading={setLoading} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    </Paper>
            </Box>
        </Box>
    )
}

export default Members