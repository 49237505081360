import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import StyledTextField from "../../../../../../Components/common/Inputs";
import { AccountNumberFormat } from "../../../../../../HelperFunctions/PhoneNumberFormat";
import StyledButton from "../../../../../../Components/common/Buttons";
import StyledDrawer from "../../../../../../Components/common/Drawer";
import AuthContext from "../../../../../../Context/AuthContext";
import useAxios from "../../../../../../utils/useAxios";

const LoanRepayment = ({
  loanStep,
  userData,
  state,
  setState,
  setLoading,
  month,
  amount,
  schedule_id,
  title,
  repaymentType
}) => {
  const { user, setToastMessage, setToastSeverity, setToastState } =
    React.useContext(AuthContext);
  const axios = useAxios();

  const [paymentData, setPaymentData] = React.useState({
    deposit_type: repaymentType,
    transaction_type: "CR",
    amount: Number(amount),
    month: month,
    schedule_id: schedule_id,
  });
  console.log(paymentData)
  const [paymentError, setPaymentError] = React.useState({
    deposit_type: "",
    transaction_type: "",
    amount: "",
    month: "",
    schedule_id: "",
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };
  const dataInputChange = (e) => {
    setPaymentData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    setLoading(true);
    let form = document.getElementById(`loanRepaymentForm`);
    if (!form.reportValidity()) {
      setLoading(false);
      return;
    }

    if (paymentData?.amount > userData?.wallet_balance) {
      setPaymentError((prevState) => ({
        ...prevState,
        amount: "Insufficient balance.",
      }));
      return;
    }

    setPaymentError({
      deposit_type: "",
      transaction_type: "",
      amount: "",
      month: "",
      schedule_id: "",
    });
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/loans/${user.user_id}/${loanStep?.loan_key}/loan-repayment/`,
        paymentData
      )
      .then(function (res) {
        setToastMessage(`Loan ${title} repayment successful`);
        setToastSeverity("success");
        setToastState(true);
        setLoading(false);
        window.location.reload();
      })
      .catch(function (error) {
        error.response?.data?.hasOwnProperty("detail") &&
          setToastMessage(error.response.data["detail"]);
        setToastSeverity("error");
        setToastState(true);

        setPaymentError({
          deposit_type: error.response?.data?.hasOwnProperty("deposit_type")
            ? error.response.data["deposit_type"]
            : "",
          transaction_type: error.response?.data?.hasOwnProperty(
            "transaction_type"
          )
            ? error.response.data["transaction_type"]
            : "",
          amount: error.response?.data?.hasOwnProperty("amount")
            ? error.response.data["amount"]
            : "",
          month: error.response?.data?.hasOwnProperty("month")
            ? error.response.data["month"]
            : "",
          schedule_id: error.response?.data?.hasOwnProperty("schedule_id")
            ? error.response.data["schedule_id"]
            : "",
        });

        !error.response && setToastMessage(`${error.message}`);
        setToastSeverity("error");
        setToastState(true);
        setLoading(false);
      });
  };

  return (
    <StyledDrawer
      anchor={"right"}
      open={state}
      onClose={toggleDrawer("right", false)}
      onOpen={toggleDrawer("right", true)}
    >
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "2em",
          mb: 5,
          textOverflow: "wrap",
          mt: "3em",
        }}
      >
        {`Repay Loan ${title}`}
      </Typography>
      <Box
        component={"form"}
        noValidate
        autoComplete={"off"}
        id={"loanRepaymentForm"}
      >
        <StyledTextField
          required
          label={`${title} amount to be paid`}
          name={"amount"}
          sx={{ mt: "2em" }}
          onChange={dataInputChange}
          error={!!paymentError.amount}
          helperText={!!paymentError.amount ? paymentError.amount : ""}
          value={`${paymentData?.amount}`}
          InputProps={{
            readOnly: repaymentType === 'INT'? true : false,
          }}
        />
        <StyledButton
          size={"large"}
          variant={"contained"}
          sx={{ mt: "3em" }}
          onClick={handleSubmit}
        >
          Make Payment
        </StyledButton>
      </Box>
    </StyledDrawer>
  );
};

export default LoanRepayment;
