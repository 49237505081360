import React from "react";
import axios from "axios";
import {useHistory} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Loader from "../../Components/common/Loader";
import StyledButton from "../../Components/common/Buttons";
import StyledCard from "../../Components/common/Card";
import StyledTextField from "../../Components/common/Inputs";
import logo from "../../Assets/img/logo.png";
import StyledAlert from "../../Components/common/Alert";
import Header from "../../Components/common/Header";


const ReVerification = () => {
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [emailError, setEmailError] = React.useState("");

    const [message, setMessage] = React.useState(null)
    // setOtp(token);

    const handleSubmit = () => {
        setLoading(true);
        axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/account/resend-verification/`, {email: email})
            .then(function (res) {
                setMessage(`${res.data['detail']}`)
                history.push("/account/registration/verification/email/token")
            })
            .catch(function (err) {
                setEmailError(err.response.data['email']);
            })
        setLoading(false);
    }

    return (
        <>
            {loading && <Loader loading={loading}/>}
            <Header />
            <Box sx={{height: "95vh", mt: {xs: 3, sm: 0}, width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap"}}>
                <Box sx={{
                    width: {xs: "90%", sm: "50%", md: "30%", lg: "20%"},
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center"
                }}>
                    <StyledCard variant={"outlined"} sx={{p: 3}}>
                        <Box
                            sx={{
                                fontWeight: 900,
                                textAlign: "center",
                                width: "20%",
                                display: "flex",
                                alignSelf: "center"
                            }}>
                            <img src={logo} alt={"OGSCOFED logo"} width={"100%"}/>
                        </Box>
                        <Typography sx={{fontWeight: 500, mt: 3, textAlign: "center"}}>
                            Enter email to resend verification:
                        </Typography>
                        {emailError && <StyledAlert opened={true} />}

                        <Box component="form" noValidate autoComplete="off" id={"form"} sx={{mt:3}}>
                            <StyledTextField required label={"Email"} type={"email"} sx={{mt: 3}} name={"email"}
                                             onChange={(e) => setEmail(e.target.value)}
                                             value={email} error={!!emailError}
                                             helperText={!!emailError ? emailError : ""}/>
                        </Box>
                        <StyledButton variant={"contained"} size={"large"} sx={{mt:2}} onClick={handleSubmit} >
                            Send email
                        </StyledButton>
                        <Typography sx={{mt:10, textAlign: "center", fontWeight: 600}}>
                            Already have an account?
                        </Typography>
                        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", mt: 2}}>
                            <StyledButton variant={"text"} size={"large"} sx={{ml: "-8px"}}
                                          onClick={() => history.push("/account/register")}>
                                Create account
                            </StyledButton>
                            <StyledButton variant={"contained"} size={"large"} onClick={() => history.push("/account/login")}>
                                Login
                            </StyledButton>
                        </Box>
                    </StyledCard>
                </Box>
            </Box>
        </>
    )
}

export default ReVerification;