import React from "react";
import axios from "axios";
import {useHistory} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTimer } from 'react-timer-hook';

import Loader from "../../Components/common/Loader";
import StyledButton from "../../Components/common/Buttons";
import StyledCard from "../../Components/common/Card";
import StyledTextField from "../../Components/common/Inputs";
import logo from "../../Assets/img/logo.png";
import StyledAlert from "../../Components/common/Alert";
import Header from "../../Components/common/Header";
import AuthContext from "../../Context/AuthContext";


const PhoneVerification = () => {
    const { user, setToastMessage, setToastState, setToastSeverity } = React.useContext(AuthContext);
    const [status, setStatus] = React.useState(false);
    const [statusMessage, setStatusMessage] = React.useState(null);
    const [statusSeverity, setStatusSeverity] = React.useState(null);
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [OTP, setOTP] = React.useState("");
    const [OTPError, setOTPError] = React.useState("");
    const [sent, setSent] = React.useState(false)

    const time = new Date();
    time.setSeconds(time.getSeconds() + 120);
    const {
        seconds,
        minutes,
        isRunning,
        restart,
    } = useTimer({ time, onExpire: () => console.warn('onExpire called') });
    // setOtp(token);

    function restartTimer() {
        const time = new Date();
        time.setSeconds(time.getSeconds() + 120);
        restart(time)
    }

    function sendToken() {
        setLoading(true);
        axios
            .get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/account/${user.user_id}/sendotp/`)
            .then(function (res) {
                setStatus(true)
                setStatusMessage("OTP sent to phone")
                setStatusSeverity('success');
                setLoading(false);
                setSent(true);
                restartTimer()
            })
            .catch(function (error) {
                error.response &&
                    setStatus(false)
                    setStatusMessage("Could not send OTP to Phone. Reload page or try again later")
                    setStatusSeverity("error")
                    setLoading(false)

                !(error.response) &&
                    setToastMessage(`${error.message}`);
                    setToastSeverity("error");
                    setToastState(true);

            })
        setLoading(false)
    }

    const handleSubmit = () => {
        setLoading(true);
        axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/account/${user.user_id}/verify-phone/`, {otp: OTP})
            .then(function (res) {
                // logoutUser();
                setToastMessage(`Phone number verified. Please login now`);
                setToastSeverity("success");
                setToastState(true);
                history.push("/account/login")
            })
            .catch(function (err) {
                err.response && err.response?.data?.hasOwnProperty("otp") && setOTPError(err.response.data['otp'])
                err.response && err.response?.data?.hasOwnProperty("detail") && setToastMessage(err.response.data['detail'])
                err.response && err.response?.data?.hasOwnProperty("detail") && setToastSeverity("error")
                err.response && err.response?.data?.hasOwnProperty("detail") && setToastState(true)

                !(err.response) &&
                    setToastMessage(`${err.message}`);
                    setToastSeverity("error");
                    setToastState(true);
            })
        setLoading(false);
    }

    React.useEffect(() => {
        sendToken();
    }, [])

    return (
        <>
            {loading && <Loader loading={loading}/>}
            <Header />
            <Box sx={{height: "95vh", mt: {xs: 3, sm: 0}, width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap"}}>
                <Box sx={{
                    width: {xs: "90%", sm: "50%", md: "30%", lg: "20%"},
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center"
                }}>
                    <StyledCard variant={"outlined"} sx={{p: 3}}>
                        <Box
                            sx={{
                                fontWeight: 900,
                                textAlign: "center",
                                width: "20%",
                                display: "flex",
                                alignSelf: "center"
                            }}>
                            <img src={logo} alt={"OGSCOFED logo"} width={"100%"}/>
                        </Box>
                        {OTPError && <StyledAlert opened={true} />}
                        {statusMessage && <StyledAlert opened={true} message={statusMessage} severe={statusSeverity} />}

                        <Box component="form" noValidate autoComplete="off" id={"form"} sx={{mt:3}}>
                            <StyledTextField required label={"OTP"} sx={{mt: 3}} name={"OTP"}
                                             onChange={(e) => setOTP(e.target.value)}
                                             value={OTP} error={!!OTPError}
                                             helperText={!!OTPError ? OTPError : ""}/>
                        </Box>
                        {isRunning && sent &&
                            <Typography sx={{fontWeight: 400, mt: 3, textAlign: "center"}}>
                                {`Wait for 
                                ${minutes>0 ? (minutes) : ('')} ${minutes>0 ? (minutes===1 ? 'minute' : 'minutes') : ('') } 
                                ${seconds>0&&minutes>0 ? ('and') : ('')} 
                                ${seconds>0 ? (seconds) : ("")} ${seconds>0 ? (seconds===1 ? ('second') : ('seconds')) : ('')} 
                                to resend OTP`}
                            </Typography>
                        }
                        {!isRunning &&
                            <StyledButton variant={"text"} size={"medium"} onClick={()=>sendToken()}>Resend OTP</StyledButton>
                        }
                        <StyledButton variant={"contained"} size={"large"} sx={{mt:2}} onClick={handleSubmit} disabled={!status} >
                            Verify OTP
                        </StyledButton>
                        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", mt: 5}}>
                            <StyledButton variant={"text"} size={"large"} sx={{ml: "-8px"}}
                                          onClick={() => history.push("/account/user/dashboard")}>
                                Return to dashboard
                            </StyledButton>
                        </Box>
                    </StyledCard>
                </Box>
            </Box>
        </>
    )
}

export default PhoneVerification;