import axios from "axios";
import React from "react";
import AuthContext from "../../Context/AuthContext";


const UserWallet = () => {
    const [userWallet, setUserWallet] = React.useState(null);
    const { user, setToastMessage, setToastState, setToastSeverity } = React.useContext(AuthContext);
    function getUserWallet() {
        axios
            .get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/account/${user.user_id}/virtual-account-details/`)
            .then(function (response) {
                setUserWallet(response.data)
            })
            .catch(function(error) {
                !(error.response?.data) &&
                    setToastMessage(`${error.message}`);
                    setToastSeverity("error");
                    setToastState(true);
            })
    }

    React.useEffect(() => {
        getUserWallet();
    },[])

    return userWallet
}

export default UserWallet;