import {styled} from "@mui/material";
import Button from "@mui/material/Button"

const StyledButton = styled((props) => (
    <Button size={"medium"} color={"secondary"} {...props} />
))(({theme}) => ({
    textTransform: "none",
    boxShadow: "none",
    textDecoration: "none"
}))

export default StyledButton;