import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Balance from "./components/balance";
import Transactions from "./components/transactions";


const Payments = ({userData, userTransactions, userWallet}) => {

    return (
        <Box sx={{display:"flex", flexDirection:"column"}}>
            <Box sx={{width:"100%"}}>
            <Balance userData={userData} userWallet={userWallet} />
            <Box sx={{mt:5}}>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Typography sx={{fontWeight:500, fontSize:"1.5em", mb:2}}>All transactions</Typography>
                        <Transactions userTransactions={userTransactions} />
                    </Grid>
                </Grid>
            </Box>
            </Box>
        </Box>
    )
}

export default Payments;