import * as React from 'react';
import dayjs from "dayjs";
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Tooltip from "@mui/material/Tooltip";
import {MdOutlineCancel, MdOutlineVerified} from "react-icons/md";
import {useTheme} from "@mui/material";
import Zoom from "@mui/material/Zoom";
import StyledButton from '../../../../../../Components/common/Buttons';
import AuthContext from "../../../../../../Context/AuthContext";
import useAxios from "../../../../../../utils/useAxios";


export function createData(user_id, name, email, telephone, verified, balance, birth, joined, telephone_verified, referees) {
    return {
        user_id,
        name,
        email,
        telephone,
        verified,
        balance,
        birth,
        joined,
        telephone_verified,
        referees: referees
    };
}

export function Row(props) {
    const { row, setLoading } = props;
    const [open, setOpen] = React.useState(false);
    const theme = useTheme()
    const { user, setToastMessage, setToastState, setToastSeverity } = React.useContext(AuthContext);
    const axios = useAxios();

    function verifyReferee(id, verified) {
        setLoading(true)
        axios
            .patch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/account/staff/${user?.user_id}/verify-referee/${id}/`, {
                referee_verified: !verified
            })
            .then(function (response) {
                response?.data?.referee_verified ? setToastMessage("Referee verified") : setToastMessage("Referee unverified");
                response?.data?.referee_verified ? setToastSeverity("success") : setToastSeverity("error");
                setToastState(true)
                window.location.reload(true)
            })
            .catch(function(error) {

                !(error.response.data) &&
                setToastMessage(`${error.message}`);
                setToastSeverity("error");
                setToastState(true);
            })
        setLoading(false)
    }

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row?.name}
                </TableCell>
                <TableCell>{row?.email}</TableCell>
                <TableCell>{row?.telephone}{"  "}{<Tooltip title={row?.telephone_verified ? "Account active" : "Account not active"}>
                    {row?.telephone_verified ? <MdOutlineVerified color={theme.palette.primary.main} /> :
                        <MdOutlineCancel color={theme.palette.error.main}/>}</Tooltip>}</TableCell>
                <TableCell align={"center"}>
                    {<Tooltip title={row?.verified ? "Account active" : "Account not active"}>
                        {row?.verified ? <MdOutlineVerified color={theme.palette.primary.main} /> :
                            <MdOutlineCancel color={theme.palette.error.main}/>}</Tooltip>}
                </TableCell>
                <TableCell>&#8358;{row?.balance}</TableCell>
                <TableCell>{dayjs(row?.birth).format("DD MMMM YYYY")}</TableCell>
                <TableCell>{dayjs(row?.joined).format("ddd, DD MMM YYYY")}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Referees
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Telephone</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Society</TableCell>
                                        <TableCell>Position</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row?.referees?.length>=1 && row?.referees?.filter(referee => {
                                                return (referee?.coop_member===row?.email);
                                            }).map(referee => (
                                        <TableRow key={referee?.referee_email}>
                                            <TableCell component="th" scope="row">
                                                {referee?.referee_name}{"  "}
                                                {<Tooltip title={referee?.referee_verified ? "Referee verified" : "Referee not verified"} TransitionComponent={Zoom}>
                                                {referee?.referee_verified ? <MdOutlineVerified color={theme.palette.primary.main} /> :
                                                    <MdOutlineCancel color={theme.palette.error.main}/>}</Tooltip>}
                                            </TableCell>
                                            <TableCell>{referee?.referee_telephone}</TableCell>
                                            <TableCell>{referee?.referee_email}</TableCell>
                                            <TableCell>
                                                {referee?.referee_society}
                                            </TableCell>
                                            <TableCell>
                                                {referee?.referee_position}
                                            </TableCell>
                                            <TableCell>
                                                <StyledButton sx={{}} onClick={()=>verifyReferee(referee?.id, referee?.referee_verified)} variant="text">
                                                    {referee?.referee_verified ? "Unverify referee" : "Verify Referee"}
                                                </StyledButton>
                                            </TableCell>
                                        </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        verified: PropTypes.bool.isRequired,
        telephone_verified: PropTypes.bool.isRequired,
        user_id: PropTypes.number.isRequired,
        balance: PropTypes.number.isRequired,
        referees: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                telephone: PropTypes.string.isRequired,
                email: PropTypes.string.isRequired,
                society: PropTypes.string.isRequired,
                position: PropTypes.string.isRequired,
                verified: PropTypes.string.isRequired,
            }),
        ).isRequired,
        name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        telephone: PropTypes.string.isRequired,
        birth: PropTypes.string.isRequired,
        joined: PropTypes.string.isRequired,
    }).isRequired,
};