import React from  "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';

import Mobile from "./components/mobile";
import {DesktopLogo, MobileLogo} from "./components/logo";
import Links from "./components/links";
import Account from "./components/account";
import AuthContext from "../../../Context/AuthContext";
import UserData from "../../../HelperFunctions/UserData";




const pages = [
    {title: 'Officials', link: '/officials'},
    {title: 'About', link: '/'},
    {title: 'Contact', link: '/'},
];
const settings = [
    {id:0, title:'Profile', link:'/account/user/dashboard/profile'},
    {id:1, title:'Dashboard', link:'/account/user/dashboard'},
    {id:2, title:'Administration', link:'/account/administration/dashboard'},
    {id:3, title:'Logout', link:''}
];


const Header = () => {

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const { user, logoutUser } = React.useContext(AuthContext);
    const usersData= UserData()
    const userData = user ? usersData : null;
    

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    return(
        <AppBar position="absolute" sx={{backgroundColor: "transparent", boxShadow: "none", color: "#000"}}>
            <Container maxWidth="xxl">
                <Toolbar disableGutters>
                    <DesktopLogo />
                    <Mobile anchorElNav={anchorElNav} pages={pages} handleOpenNavMenu={handleOpenNavMenu} handleCloseNavMenu={handleCloseNavMenu} />
                    <MobileLogo />
                    <Links pages={pages} handleCloseNavMenu={handleCloseNavMenu} />
                    <Account anchorElUser={anchorElUser} handleOpenUserMenu={handleOpenUserMenu} handleCloseUserMenu={handleCloseUserMenu} settings={settings} user={user} userData={userData} logoutUser={logoutUser} />
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default Header;