import Grid from "@mui/material/Grid";
import StyledTextField from "../../../../Components/common/Inputs";
import Box from "@mui/material/Box";
import React from "react";
import MenuItem from "@mui/material/MenuItem";
import StyledButton from "../../../../Components/common/Buttons";


const Form2 = ({dataInputChange, memberData, memberError, allStates, allCities, handleBack, handleNext}) => {
    return (
        <Box component="form" noValidate autoComplete="off" id={"form2"}>
            <Grid container sx={{mt: 3}} spacing={1}>
                <Grid item xs={12}>
                    <StyledTextField required label={"Address"} sx={{mt: 2}}
                                     onChange={dataInputChange} name={"address"}
                                     value={memberData?.address} error={!!memberError.addressError}
                                     helperText={!!memberError.addressError ? memberError.addressError : ""}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <StyledTextField required label={"State"} sx={{mt: 2}} select
                                     name={"states"}
                                     onChange={dataInputChange}
                                     value={memberData?.states} error={!!memberError.statesError}
                                     helperText={!!memberError.statesError ? memberError.statesError : ""}>
                        {allStates.map((state) => (
                            <MenuItem value={state} key={state}>
                                {state}
                            </MenuItem>
                        ))}
                    </StyledTextField>
                </Grid>
                <Grid item xs={12} md={6}>
                    <StyledTextField required select label={"Local government"} sx={{mt: 2}}
                                     name={"city"}
                                     onChange={dataInputChange}
                                     value={memberData?.city} error={!!memberError.cityError}
                                     helperText={!!memberError.cityError ? memberError.cityError : ""}>
                        {allCities ? (allCities.map((city) => (
                            <MenuItem value={city} key={city}>
                                {city}
                            </MenuItem>
                        ))) : (<MenuItem disabled>Choose a state</MenuItem>)}
                    </StyledTextField>
                </Grid>
            </Grid>
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 10
            }}>
                <StyledButton variant={"text"} size={"large"} sx={{ml: "-8px"}}
                              onClick={handleBack}>
                    Back
                </StyledButton>
                <StyledButton variant={"contained"} size={"large"} onClick={handleNext}>
                    Next
                </StyledButton>
            </Box>
        </Box>
    )
}

export default Form2;