import Grid from "@mui/material/Grid";
import StyledTextField from "../../../../Components/common/Inputs";
import Box from "@mui/material/Box";
import React from "react";
import StyledButton from "../../../../Components/common/Buttons";


const Form0 = ({dataInputChange, memberData, memberError, handleNext}) => {
    return (
        <Box component="form" noValidate autoComplete="off" id={"form0"}>
            <Grid container sx={{mt: 3}} spacing={1}>
                <Grid item xs={12}>
                    <StyledTextField required label={"First name"} name={"first_name"}
                                     onChange={dataInputChange} error={!!memberError.firstNameError}
                                     helperText={!!memberError.firstNameError ? memberError.firstNameError : ""}
                                     value={memberData?.first_name}/>
                </Grid>
                <Grid item xs={12}>
                    <StyledTextField required label={"Last name"} sx={{mt: 2}} name={"last_name"}
                                     onChange={dataInputChange} error={!!memberError.lastNameError}
                                     helperText={!!memberError.lastNameError ? memberError.lastNameError : ""}
                                     value={memberData?.last_name}/>
                </Grid>
                <Grid item xs={12}>
                    <StyledTextField label={"Other name"} sx={{mt: 2}} name={"other_name"}
                                     onChange={dataInputChange} error={!!memberError.otherNameError}
                                     helperText={!!memberError.otherNameError ? memberError.otherNameError : ""}
                                     value={memberData?.other_name}/>
                </Grid>
            </Grid>
            <Box sx={{display: "flex", justifyContent: "end", alignItems: "center", mt: 10}}>
                <StyledButton variant={"contained"} size={"large"} onClick={handleNext}>
                    Next
                </StyledButton>
            </Box>
        </Box>
    )
}

export default Form0;