import React from 'react';
import Box from "@mui/material/Box";
import logo from "../../../../../../Assets/img/logo.png"
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import StyledTextField from "../../../../../../Components/common/Inputs";

export const LoanDocument = ({loanData, userData}) => {

    return (
    <Box sx={{padding:"1rem", display:"flex", height:"100%",
        flexDirection:"column", alignItems:"center", border:"2px solid #820F65", borderRadius: "2rem"}}>
        <Box sx={{width:"8rem", display:"flex", justifyContent:"center"}}>
            <img src={logo} alt={"COOP logo"} width={"100%"} height={"auto"}  />
        </Box>
        <Box sx={{mt:"2rem", width:"100%"}}>
            <Typography sx={{fontWeight:500, fontSize:"2rem", textAlign:"center"}}>
                Loan Information
            </Typography>
            <Grid container spacing={3} sx={{mt:"3rem"}}>
                <Grid item xs={12}>
                    <StyledTextField required fullWidth label={"Full Name"}
                                    value={`${userData?.first_name} ${userData?.last_name} ${userData?.other_name}`} />
                </Grid>
                <Grid item xs={12}>
                    <StyledTextField required fullWidth label={"Loan Purpose"} value={loanData.loan_purpose} />
                </Grid>
                <Grid item xs={6}>
                    <StyledTextField required fullWidth label={"Loan Amount"} value={loanData.loan_amount} />
                </Grid>
                <Grid item xs={6}>
                    <StyledTextField required fullWidth label={"Loan Duration"} value={loanData.loan_duration} />
                </Grid>
                <Grid item xs={6}>
                    <StyledTextField required fullWidth label={"Surety one full name"} value={loanData.surety_one} />
                </Grid>
                <Grid item xs={6}>
                    <StyledTextField required fullWidth label={"Surety two full name"} value={loanData.surety_two} />
                </Grid>
                <Grid item xs={6}>
                    <StyledTextField required fullWidth label={"Surety one signature"} value={"  "} />
                </Grid>
                <Grid item xs={6}>
                    <StyledTextField required fullWidth label={"Surety two signature"} value={"  "} />
                </Grid>
                <Grid item xs={12}>
                    <StyledTextField required fullWidth label={"Signature"} value={"  "} />
                </Grid>
            </Grid>
            <Typography sx={{fontWeight:700, fontSize:"2rem", textAlign:"center", position:"absolute", bottom:0, mb:"1rem"}}>
                Fill all the above information and submit back for screening
            </Typography>
        </Box>
    </Box>
    )
}
