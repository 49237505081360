import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useHistory} from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import StyledButton from "../../../../../Components/common/Buttons";
import Grid from "@mui/material/Grid";
import Balance from "./components/balance";
import Transactions from "./components/transactions";


const Home = ({userData, userTransactions, userWallet}) => {
    const history = useHistory();

    return (
        <Box sx={{display:"flex", flexDirection:"column"}}>
            {/*<Box sx={{width:"100%"}}>*/}
            <Typography sx={{fontWeight:500, fontSize:"2em"}}>Welcome, {userData?.first_name && userData?.first_name}</Typography>
            <Box sx={{backgroundColor:"#9b3f8317", paddingX:"2em", marginBottom:"2em", borderRadius:"1em", height:"12em", display:"flex", alignItems:"center", justifyContent:"space-between", mt:2}}>
                <Box sx={{display:"flex", alignItems:"center"}}>
                    <Avatar src={`${process.env.REACT_APP_BACKEND_ENDPOINT}${userData?.profile_image}`} sx={{ width:"6em", height:"6em", mr:2, backgroundColor:"#000" }} />
                    <Typography sx={{fontSize:"2em", fontWeight:500, display:{xs:"none", sm:"flex"} }}>{userData?.first_name} {userData?.other_name} {userData?.last_name}</Typography>
                </Box>
                <Box sx={{display:"flex", flexDirection:"column"}}>
                    <StyledButton sx={{fontWeight:300, mt:1}} size={"large"} variant={"outlined"} onClick={()=>history.push('/account/password/change')}>Change Password</StyledButton>
                    {!(userData?.telephone_verified) && <StyledButton sx={{fontWeight: 300, mt: 1}} size={"large"} variant={"outlined"}
                                   onClick={() => history.push('/account/phone-verification')}>Verify phone</StyledButton>}
                </Box>
            </Box>
            <Balance userData={userData} userWallet={userWallet} />
            <Box sx={{mt:5}}>
                <Grid container spacing={{xs:5, xl:10}}>
                    <Grid item xs={12} md={7}>
                        <Typography sx={{fontWeight:500, fontSize:"1.5em", mb:2}}>Transactions</Typography>
                        <Transactions userTransactions={userTransactions} />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Typography sx={{fontWeight:500, fontSize:"1.5em", mb:2}}>Notifications</Typography>
                        <Typography>NO notifications yet!</Typography>
                    </Grid>
                </Grid>
            {/*</Box>*/}
            </Box>
        </Box>
    )
}

export default Home