import React from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";

import {AccountNumberFormat} from "../../../../../../HelperFunctions/PhoneNumberFormat";
import StyledTextField from "../../../../../../Components/common/Inputs";
import StyledDrawer from "../../../../../../Components/common/Drawer";
import StyledButton from "../../../../../../Components/common/Buttons";
import AuthContext from "../../../../../../Context/AuthContext";
import BanksList from "../../../../../../HelperFunctions/BanksList";


const CreateAccount = ({state, setState, setLoading}) => {
    const { user, setToastMessage, setToastSeverity, setToastState } = React.useContext(AuthContext);
    const banks = BanksList()
    let data_event;

    const [accountData, setAccountData] = React.useState({
        email: "",
        first_name: "",
        last_name: "",
        account_number: "",
        bvn: "",
        bank_code: "",
        country: "NG",
        type: "bank_account",
        preferred_bank: "wema-bank",
        // subaccount: "ACCT_edff0dmy8yri98o",
    })
    const [accountError, setAccountError] = React.useState({
        emailError: "",
        first_nameError: "",
        last_nameError: "",
        account_numberError: "",
        bvnError: "",
        bank_codeError: ""
    })
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState(open);
    };
    const dataInputChange = (e) => {
        setAccountData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    // function to check if webhook event data has been saved
    function getPaystackEvent() {
        axios
            .get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/account/${user.user_id}/webhook-data/`)
            .then(function(res) {
                if (res.data.event === 'customeridentification.failed') {
                    setToastMessage('Account number or BVN incorrect.');
                    setToastSeverity("error");
                    setToastState(true)
                } else if (res.data.event === 'customeridentification.success') {
                    setToastMessage('Account identification Success, and Virtual account number created');
                    setToastSeverity("success");
                    setToastState(true)
                }
                clearInterval(data_event);
                setLoading(false);
            })
            .catch(function (error) {
            })
    }

    const createAccount = () => {
        setLoading(true);
        let form = document.getElementById(`virtualAccountForm`);
        if (!form.reportValidity()) {
            setLoading(false)
            return;
        }
        axios
            .post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/account/${user.user_id}/virtual-account-creation/`, accountData)
            .then(function(res) {
                // setLoading(false)
                setToastMessage(res.data.message);
                setToastSeverity("info");
                setToastState(true)
                data_event = setInterval(getPaystackEvent, 5000);
            })
            .catch(function(error) {
                error.response &&
                    setToastMessage(error.response.data.message)

                !(error.response) &&
                setToastMessage(`${error.message}`);
                setToastSeverity("error");
                setToastState(true);
                setLoading(false);
            })
    }

    return (
        // <Box>
            <StyledDrawer
                anchor={'right'}
                open={state}
                onClose={toggleDrawer('right', false)}
                onOpen={toggleDrawer('right', true)}
            >
                <Typography sx={{fontWeight:600, fontSize:"2em", textOverflow:"wrap", mt:"3em"}}>
                    Create Virtual Account
                </Typography>
                <Typography sx={{fontWeight:500, fontSize:"1.25em", mb:5, textOverflow:"wrap", mt:"1.5em"}}>
                    The virtual account acts as a wallet, and is for depositing money for all cooperative activities.
                </Typography>
                <Box component={"form"} noValidate autoComplete={"off"} id={"virtualAccountForm"}>
                <StyledTextField required label={"First name"} name={"first_name"}
                                 sx={{mt:"2em"}}
                                 onChange={dataInputChange} error={!!accountError.firstNameError}
                                 helperText={!!accountError.firstNameError ? accountError.firstNameError : ""}
                                 value={accountData?.first_name}/>
                <StyledTextField required label={"Last name"} sx={{mt:"2em"}} name={"last_name"}
                                 onChange={dataInputChange} error={!!accountError.lastNameError}
                                 helperText={!!accountError.lastNameError ? accountError.lastNameError : ""}
                                 value={accountData?.last_name}/>
                <StyledTextField required label={"Email address"} type={"email"}
                                 sx={{mt:"2em"}} name={"email"}
                                 onChange={dataInputChange} error={!!accountError.emailError}
                                 helperText={!!accountError.emailError ? accountError.emailError : ""}
                                 value={accountData?.email}/>
                <StyledTextField required label={"BVN"} sx={{mt:"2em"}}
                                 InputProps={{
                                     inputComponent: AccountNumberFormat,
                                 }} name={"bvn"}
                                 onChange={dataInputChange} error={!!accountError.bvnError}
                                 helperText={!!accountError.bvnError ? accountError.bvnError : ""}
                                 value={accountData?.bvn}/>
                <StyledTextField required label={"Account number"} sx={{mt:"2em"}}
                                 InputProps={{
                                     inputComponent: AccountNumberFormat,
                                 }} name={"account_number"}
                                 onChange={dataInputChange} error={!!accountError.account_numberError}
                                 helperText={!!accountError.account_numberError ? accountError.account_numberError : "Existing bank account"}
                                 value={accountData?.account_number}/>
                <StyledTextField required label={"Bank"} sx={{mt:"2em"}} select
                                 name={"bank_code"}
                                 onChange={dataInputChange}
                                 value={accountData?.bank_code} error={!!accountError.bank_codeError}
                                 helperText={!!accountError.bank_codeError ? accountError.bank_codeError : ""}>
                    {banks && banks.map((bank) => (
                        <MenuItem value={bank.code} key={bank.id}>
                            {bank.name}
                        </MenuItem>
                    ))}
                </StyledTextField>
                <StyledButton size={"large"} variant={"contained"} sx={{mt:"3em"}} onClick={createAccount}>Create account</StyledButton>
                </Box>
            </StyledDrawer>
        // </Box>
    )
}

export default CreateAccount;