import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

import StyledButton from "../../../../../../../Components/common/Buttons";
import AuthContext from "../../../../../../../Context/AuthContext";
import useAxios from "../../../../../../../utils/useAxios";
import {RefereeOne, RefereeTwo} from "./components/Form";
import Tooltip from "@mui/material/Tooltip";
import {Verified} from "@mui/icons-material";
import Zoom from "@mui/material/Zoom";


const MyReferees = ({setLoading, userReferee}) => {
    const { user, setToastMessage, setToastSeverity, setToastState } = React.useContext(AuthContext);
    const axios = useAxios();
    const [status, setStatus] = React.useState(0)
    const [newRefereeData1, setNewReferee1] = React.useState(
        {
            referee_name: "",
            referee_email: "",
            referee_telephone: "",
            referee_society: "",
            referee_position: ""
        })
    const [newRefereeData2, setNewReferee2] = React.useState(
        {
            referee_name: "",
            referee_email: "",
            referee_telephone: "",
            referee_society: "",
            referee_position: ""
        })
    const [newRefereeDataError, setNewRefereeError] = React.useState([
        {
            referee_nameError: "",
            referee_emailError: "",
            referee_telephoneError: "",
            referee_societyError: "",
            referee_positionError: ""
        },
        {
            referee_nameError: "",
            referee_emailError: "",
            referee_telephoneError: "",
            referee_societyError: "",
            referee_positionError: ""
        }
    ])


    const dataInputChange1 = (e) => {
        setNewReferee1((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const dataInputChange2 = (e) => {
        setNewReferee2((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    function handleSubmit() {
        setLoading(true)
        const data = status===0 ? [newRefereeData1, newRefereeData2] : [newRefereeData2]
        axios
            .post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/account/${user.user_id}/referee/`, data)
            .then(function (response) {
                window.location.reload();
                setToastMessage("Referee added successfully")
                setToastSeverity("success")
                setToastState(true);
                setLoading(false);
                window.location.reload()
            })
            .catch(function(error) {
                setNewRefereeError([
                    {
                        referee_nameError: error.response.data[0].hasOwnProperty("referee_name") && error.response.data[0]["referee_name"],
                        referee_emailError: error.response.data[0].hasOwnProperty("referee_email") && error.response.data[0]["referee_email"],
                        referee_telephoneError: error.response.data[0].hasOwnProperty("referee_telephone") && error.response.data[0]["referee_telephone"],
                        referee_societyError: error.response.data[0].hasOwnProperty("referee_society") && error.response.data[0]["referee_society"],
                        referee_positionError: error.response.data[0].hasOwnProperty("referee_position") && error.response.data[0]["referee_position"]
                    },
                    {
                        referee_nameError: error.response.data[1].hasOwnProperty("referee_name") && error.response.data[1]["referee_name"],
                        referee_emailError: error.response.data[1].hasOwnProperty("referee_email") && error.response.data[1]["referee_email"],
                        referee_telephoneError: error.response.data[1].hasOwnProperty("referee_telephone") && error.response.data[1]["referee_telephone"],
                        referee_societyError: error.response.data[1].hasOwnProperty("referee_society") && error.response.data[1]["referee_society"],
                        referee_positionError: error.response.data[1].hasOwnProperty("referee_position") && error.response.data[1]["referee_position"]
                    }
                ])
                setLoading(false)
            })
    }

    React.useEffect(() => {
        userReferee?.length===1 && setNewReferee1(userReferee[0]);
        userReferee?.length===2 && setNewReferee1(userReferee[0]);
        userReferee?.length===2 && setNewReferee2(userReferee[1]);
        userReferee?.length===1 && setStatus(1);
        userReferee?.length===2 && setStatus(2);
    }, [])
    return (
        <Box>
            <Box>
                <Box sx={{display:"flex", alignItems:"center"}}>
                    <Typography sx={{fontWeight:500, fontSize:"1.5em"}}>
                        Referee 1
                    </Typography>
                    {userReferee?.length>=1 && userReferee[0].referee_verified &&
                        <Tooltip title={"Verified Referee"} TransitionComponent={Zoom} >
                            <Verified color={"primary"} />
                        </Tooltip>
                    }
                </Box>
                    <RefereeOne referee={newRefereeData1} editable={(status===0)}
                                refereeError={newRefereeDataError} dataInputChange={dataInputChange1} />

                <Box sx={{display:"flex", alignItems:"center", mt:5}}>
                    <Typography sx={{fontWeight:500, fontSize:"1.5em"}}>
                        Referee 2
                    </Typography>
                    {userReferee?.length===2 && userReferee[1].referee_verified &&
                        <Tooltip title={"Verified Referee"}  TransitionComponent={Zoom} >
                            <Verified color={"primary"} />
                        </Tooltip>
                    }
                </Box>
                <RefereeTwo referee={newRefereeData2} editable={!(status===2)}
                            refereeError={newRefereeDataError} dataInputChange={dataInputChange2} />
            </Box>
            <Box>
                <StyledButton variant={"contained"} disabled={(status===2)} size={"large"} sx={{height: "3.7em", mt:"5em"}} onClick={handleSubmit}>
                    Submit
                </StyledButton>
            </Box>
        </Box>
    )
}

export default  MyReferees