import React from "react";
import {useHistory, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Loader from "../../Components/common/Loader";
import StyledButton from "../../Components/common/Buttons";
import StyledCard from "../../Components/common/Card";
import StyledTextField from "../../Components/common/Inputs";
import logo from "../../Assets/img/logo.png";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import axios from "axios";
import StyledAlert from "../../Components/common/Alert";
import Header from "../../Components/common/Header";
import AuthContext from "../../Context/AuthContext";



const ForgotPassword = () => {
    const {setToastMessage, setToastSeverity, setToastState} = React.useContext(AuthContext);
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [severity, setSeverity] = React.useState("error");
    const [validated, setValidated] = React.useState(false);

    const {token, uidb64} = useParams();

    const [showPassword, setShowPassword] = React.useState(false);
    const [showPassword2, setShowPassword2] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleMouseDownPassword2 = (event) => {
        event.preventDefault();
    };

    const [memberData, setMemberData] = React.useState({
        password: "",
        password2: "",
        token: token,
        uidb64: uidb64
    })

    const [memberError, setMemberError] = React.useState({
        error: false,
        errorMessage: "",
        passwordError: "",
        password2Error: "",
    })

    const dataInputChange = (e) => {
        setMemberData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    function validateToken () {
        setLoading(true)
        axios
            .get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/account/password-reset/${uidb64}/${token}/`)
            .then(function (res) {
                setSeverity("success")
                setMessage(res.data['detail'])
                setLoading(false)
                setValidated(true)
            })
            .catch(function (error) {
                setSeverity("error")
                setMessage(error.response.data['detail'])
                setLoading(false)
                setValidated(false)
            })
    }

    const handleSubmit = () => {
        setLoading(true);
        axios
            .patch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/account/password-reset-complete/`, memberData)
            .then(function (res) {
                setLoading(false)
                history.push('/account/login');
                setToastMessage("Account reset complete");
                setToastSeverity("success");
                setToastState(true);
            })
            .catch(function (error) {
                error.response &&
                    setMemberError({
                        error: true,
                        errorMessage: error.response.data.hasOwnProperty("detail") ? error.response.data["detail"] : "",
                        passwordError: error.response.data.hasOwnProperty("password") ? error.response.data["password"] : "",
                        password2Error: error.response.data.hasOwnProperty("password2") ? error.response.data["password2"] : "",
                    })
                !(error.response) &&
                    setToastMessage(`${error.message}`);
                    setToastSeverity("error");
                    setToastState(true);
                setLoading(false)
            })
    }

    React.useEffect(() => {
        if (!validated) {
            validateToken()
        }
    })

    return (
        <>
            {loading && <Loader loading={loading}/>}
            <Header />
            <Box sx={{height: "95vh", mt: {xs: 3, sm: 0}, width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap"}}>
                <Box sx={{
                    width: {xs: "90%", sm: "50%", md: "30%", lg: "20%"},
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center"
                }}>
                    <StyledCard variant={"outlined"} sx={{p: 3}}>
                        <Box
                            sx={{
                                fontWeight: 900,
                                textAlign: "center",
                                width: "20%",
                                display: "flex",
                                alignSelf: "center"
                            }}>
                            <img src={logo} alt={"OGSCOFED logo"} width={"100%"}/>
                        </Box>
                        <Typography sx={{fontWeight: 500, mt: 3, textAlign: "center"}}>
                            Enter verification code in the space provided below:
                        </Typography>
                        {(message  || memberError.error) && <StyledAlert opened={true} message={message ? message : memberError.errorMessage} severe={severity} />}
                        <Box component="form" noValidate autoComplete="off" id={"form"} sx={{mt:3}}>
                            <Grid item xs={12}>
                                <StyledTextField
                                    required
                                    label={"Enter new password"} sx={{mt: 2}}
                                    type={showPassword ? 'text' : 'password'}
                                    name={"password"}
                                    onChange={dataInputChange}
                                    value={memberData?.password}
                                    error={!!memberError.passwordError}
                                    helperText={!!memberError.passwordError ? memberError.passwordError : ""}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff/> :
                                                        <Visibility/>}
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <StyledTextField
                                    required
                                    label={"Confirm new password"} sx={{mt: 2}}
                                    type={showPassword2 ? 'text' : 'password'}
                                    name={"password2"}
                                    onChange={dataInputChange}
                                    value={memberData?.password2}
                                    error={!!memberError.password2Error}
                                    helperText={!!memberError.password2Error ? memberError.password2Error : ""}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword2}
                                                    onMouseDown={handleMouseDownPassword2}
                                                    edge="end"
                                                >
                                                    {showPassword2 ? <VisibilityOff/> :
                                                        <Visibility/>}
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                />
                            </Grid>
                        </Box>
                        <StyledButton variant={"contained"} size={"large"} sx={{mt:2}} onClick={handleSubmit} disabled={!validated}>
                            Change password
                        </StyledButton>
                        <Typography sx={{mt:10, textAlign: "center", fontWeight: 600}}>
                            Already have an account?
                        </Typography>
                        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", mt: 2}}>
                            <StyledButton variant={"text"} size={"large"} sx={{ml: "-8px"}}
                                          onClick={() => history.push("/account/register")}>
                                Create account
                            </StyledButton>
                            <StyledButton variant={"contained"} size={"large"} onClick={() => history.push("/account/login")}>
                                Login
                            </StyledButton>
                        </Box>
                    </StyledCard>
                </Box>
            </Box>
        </>
    )
}

export default ForgotPassword;