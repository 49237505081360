import React from "react"
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import StyledTextField from "../../../../../../Components/common/Inputs";
import MenuItem from "@mui/material/MenuItem";
import StyledDrawer from "../../../../../../Components/common/Drawer";
import {AccountNumberFormat} from "../../../../../../HelperFunctions/PhoneNumberFormat";
import axios from "axios";
import AuthContext from "../../../../../../Context/AuthContext";
import StyledButton from "../../../../../../Components/common/Buttons";


const paymentOptions = [
    {id:0, title:"Shares", value:"SAV"},
    {id:1, title:"Extra savings", value:"EXS"},
    {id:2, title:"Other Payments", value:"OTH"},
]

const transactionType = [
    {id:0, title:"DEPOSIT", value:"CR"},
    {id:1, title:"WITHDRAW", value:"DR"},
]


const MakePayment = ({state, setState, setLoading, userData, loading}) => {
    const { user, setToastMessage, setToastSeverity, setToastState } = React.useContext(AuthContext);
    const [paymentData, setPaymentData] = React.useState({
        deposit_type: "",
        transaction_type: "",
        actual_amount: "",
        description: ""
    })
    const [paymentError, setPaymentError] = React.useState({
        deposit_typeError: "",
        transaction_typeError: "",
        actual_amountError: "",
        descriptionError: ""
    })
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState(open);
    };
    const dataInputChange = (e) => {
        setPaymentData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = () => {
        let form = document.getElementById(`paymentForm`);
        if (!form.reportValidity()) {
            setLoading(false)
            return;
        }

        if (paymentData?.actual_amount > userData?.total_extrasavings && paymentData?.transaction_type==='DR') {
            setPaymentError((prevState) => ({
                ...prevState,
                actual_amountError: "Insufficient balance."
            }))
            return;
        }
        setLoading(true);

        setPaymentError({
            deposit_typeError: "",
            transaction_typeError: "",
            actual_amountError: "",
            descriptionError: ""
        })
        axios
            .post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/transactions/${user.user_id}/transaction/`, paymentData)
            .then(function(res) {
                setToastMessage('Payment successful');
                setToastSeverity("success");
                setToastState(true);
                setLoading(false);
                window.location.reload()
            })
            .catch(function(error) {
                error.response &&
                setToastMessage(error.response?.data?.hasOwnProperty("status") ?
                    `${error.response.data["status"]}: ${error.response.data["message"]}` :
                    `You have errors in your form`);
                setToastSeverity("error");
                setToastState(true);
                setPaymentError({
                    deposit_typeError: error.response?.data?.hasOwnProperty("deposit_type") ? error.response.data["deposit_type"] : "",
                    transaction_typeError: error.response?.data?.hasOwnProperty("transaction_type") ? error.response.data["transaction_type"] : "",
                    actual_amountError: error.response?.data?.hasOwnProperty("actual_amount") ? error.response.data["actual_amount"] : "",
                    descriptionError: error.response?.data?.hasOwnProperty("description") ? error.response.data["description"] : "",
                })

                !(error.response) &&
                setToastMessage(`${error.message}`);
                setToastSeverity("error");
                setToastState(true);
                setLoading(false);
            })
        setLoading(false)
    }
    return (
        // <Box>
            <StyledDrawer
                anchor={'right'}
                open={state}
                onClose={toggleDrawer('right', false)}
                onOpen={toggleDrawer('right', true)}
            >
                <Typography sx={{fontWeight:600, fontSize:"2em", mb:5, textOverflow:"wrap", mt:"3em"}}>
                    Make payment
                </Typography>
                <Box component={"form"} noValidate autoComplete={"off"} id={"paymentForm"}>
                    <StyledTextField required label={"Description"} name={"description"}
                        sx={{mt:"2em"}}
                        onChange={dataInputChange} error={!!paymentError.descriptionError}
                        helperText={!!paymentError.descriptionError ? paymentError.descriptionError : ""}
                        value={paymentData?.description}
                    />
                    <StyledTextField required select label={"Transaction type"} sx={{mt:"2em"}}
                        name={"transaction_type"}
                        onChange={dataInputChange}
                        
                        value={paymentData?.transaction_type} error={!!paymentError.transaction_typeError}
                        helperText={!!paymentError.transaction_typeError ? paymentError.transaction_typeError : ""}
                    >
                        {(transactionType.map((opt) => (
                            <MenuItem value={opt.value} key={opt.id} disabled={opt.value === "DR" ? true : false}>
                                {opt.title}
                            </MenuItem>
                        )))}
                    </StyledTextField>
                    <StyledTextField required select label={"Deposit type"} sx={{mt:"2em"}}
                        name={"deposit_type"}
                        onChange={dataInputChange}
                        value={paymentData?.deposit_type} error={!!paymentError.deposit_typeError}
                        helperText={!!paymentError.deposit_typeError ? paymentError.deposit_typeError : ""}
                    >
                        {(paymentOptions.map((opt) => (
                            <MenuItem value={opt.value} key={opt.id}>
                                {opt.title}
                            </MenuItem>
                        )))}
                    </StyledTextField>
                    <StyledTextField required label={"Amount"} name={"actual_amount"} sx={{mt:"2em"}}
                        onChange={dataInputChange} error={!!paymentError.actual_amountError}
                        helperText={!!paymentError.actual_amountError ? paymentError.actual_amountError : ""}
                        value={paymentData?.actual_amount}
                        InputProps={{
                                        inputComponent: AccountNumberFormat,
                                    }}
                    />
                    <StyledButton disabled={loading} size={"large"} variant={"contained"} sx={{mt:"3em"}} onClick={handleSubmit}>Submit Transaction</StyledButton>
                </Box>
            </StyledDrawer>
        // </Box>
    )
}

export default MakePayment;