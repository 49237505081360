import React, { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import {useHistory} from "react-router-dom";
import axios from "axios";


const AuthContext = createContext();
export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );

  const [toastMessage, setToastMessage] = useState("");
  const [toastState, setToastState] = useState(false);
  const [toastSeverity, setToastSeverity] = useState("error");
  const [toastTitle, setToastTitle] = useState(null);

  const loginUser = async (userData, setErrorData, setLoading, state=null) => {
      setLoading(true);
      // userData.email[0]==='0' && setUserData((prevState)=>({...prevState,telephone: `+234${(userData.email).slice(1)}`}))
      // userData.email[0]==='+' && setUserData((prevState)=>({...prevState,telephone: `${userData.email}`}))
      let url = userData?.telephone[0]==='+' ? `${process.env.REACT_APP_BACKEND_ENDPOINT}/account/login/phone/` : `${process.env.REACT_APP_BACKEND_ENDPOINT}/account/login/`;
    await axios
        .post(url, userData)
        .then(function (res) {
          setAuthTokens(res.data);
          setUser(jwt_decode(res.data.access));
          localStorage.setItem("authTokens", JSON.stringify(res.data));
          setLoading(false);
          history.push(`/account/user/dashboard`);
          setToastMessage("Login successfully")
          setToastSeverity("success")
          setToastState(true);
        })
        .catch(function (error) {
            error.response &&
                setErrorData({
                    error: true,
                    errorMessage: error.response?.data?.hasOwnProperty("detail") ? error.response.data["detail"] : "",
                    emailError: error.response?.data?.hasOwnProperty("email") ? error.response.data["email"] : "",
                    passwordError: error.response?.data?.hasOwnProperty("password") ? error.response.data["password"] : "",
                })
                setToastMessage("You have errors in your form");

            !(error.response) &&
                setToastMessage(`${error.message}`);
                setToastSeverity("error");
                setToastState(true);
          setLoading(false);
        })
  };

  const registerUser = (userData, setErrorData, setLoading) => {
      setLoading(true);
    axios
        .post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/account/register/`, userData, {headers:{'content-type': 'multipart/form-data'}})
        .then(function (res) {
          setLoading(false);
          history.push("/account/registration/verification/email/token");
            setToastMessage("Registration successful. Email verification sent")
            setToastSeverity("success")
            setToastState(true);
        })
        .catch(function (error) {
          error.response &&
            setErrorData({
              error: true,
              errorMessage: error.response.data.hasOwnProperty("detail") ? error.response.data["detail"] : "",
              firstNameError: error.response.data.hasOwnProperty("first_name") ? error.response.data["first_name"] : "",
              lastNameError: error.response.data.hasOwnProperty("last_name") ? error.response.data["last_name"] : "",
              otherNameError: error.response.data.hasOwnProperty("other_name") ? error.response.data["other_name"] : "",
              telephoneError: error.response.data.hasOwnProperty("telephone") ? error.response.data["telephone"] : "",
              emailError: error.response.data.hasOwnProperty("email") ? error.response.data["email"] : "",
              occupationError: error.response.data.hasOwnProperty("occupation") ? error.response.data["occupation"] : "",
              DOBError: error.response.data.hasOwnProperty("date_of_birth") ? error.response.data["date_of_birth"] : "",
              addressError: error.response.data.hasOwnProperty("address") ? error.response.data["address"] : "",
              statesError: error.response.data.hasOwnProperty("states") ? error.response.data["states"] : "",
              cityError: error.response.data.hasOwnProperty("city") ? error.response.data["city"] : "",
              passwordError: error.response.data.hasOwnProperty("password") ? error.response.data["password"] : "",
              password2Error: error.response.data.hasOwnProperty("password2") ? error.response.data["password2"] : "",
              profilePictureError: error.response.data.hasOwnProperty("profile_image") ? error.response.data["profile_image"] : "",
            })
            setToastMessage("You have errors in your form");

            !(error.response) &&
                setToastMessage(`${error.message}`);
                setToastSeverity("error");
                setToastState(true);
          setLoading(false);
        })
  };

  const logoutUser = () => {
      window.location.reload();
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem("authTokens");
      setToastMessage("Logged out successfully")
      setToastSeverity("info")
      setToastState(true);
  };

  const contextData = {
    user,
    setUser,
    authTokens,
    setAuthTokens,
    registerUser,
    loginUser,
    logoutUser,
      toastMessage,
      setToastMessage,
      toastState,
      setToastState,
      toastSeverity,
      setToastSeverity,
      toastTitle,
      setToastTitle
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwt_decode(authTokens.access));
    }
    setLoading(false);
  }, [authTokens, loading]);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
