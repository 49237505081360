import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import Card from "@mui/material/Card";
import { useTheme } from "@mui/material";

import StyledButton from "../../../../../Components/common/Buttons";
import LoanApplication from "./components/LoanApplication";
import AuthContext from "../../../../../Context/AuthContext";
import GetLoanApplication from "../../../../../HelperFunctions/GetLoanApplication";
import LoanProgress from "./components/LoanProgress";
import LoanSchedule from "./components/LoanSchedule";
import useAxios from "../../../../../utils/useAxios";

const loanSteps = {
  One: 1,
  Two: 2,
  Three: 3,
  Four: 4,
};

const Loans = ({
  userData,
  userWallet,
  userTransactions,
  userReferee,
  setLoading,
  loading
}) => {
  const axios = useAxios();
  const { user, setToastMessage, setToastSeverity, setToastState } =
    React.useContext(AuthContext);
  const theme = useTheme();
  const [state, setState] = React.useState(false);
  const [sureties, setSureties] = React.useState();
  const applications = GetLoanApplication();
  const [progress, setProgress] = React.useState(25);
  const [latestStep, setLatestStep] = React.useState(null);
  const disabled =
    userReferee &&
    userReferee.length === 2 &&
    userData?.profile_image !== "" &&
    userWallet?.account_number !== "" &&
    !!applications?.date_created;

  function getSureties() {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/loans/${user.user_id}/sureties/`
      )
      .then(function (res) {
        setSureties(res.data);
      })
      .catch(function (err) {
        err.response && setToastMessage(`Coudn't fetch available sureties`);
        setToastSeverity("error");
        setToastState(true);

        !err.response && setToastMessage(`${err.message}`);
        setToastSeverity("error");
        setToastState(true);
      });
  }

  async function downloadBond() {
    setLoading(true);
    await fetch(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/loans/${user.user_id}/download-bond/${applications?.id}/`,
      {
        method: 'GET',
        headers: {
          "Connection": "Keep-Alive",
          "Keep-Alive": "timeout=90"
        }
      }
    ).then(response => {
      // console.log(response)
    }).catch(error=>{
      !error.response && setToastMessage(`${error.message}`);
        setToastSeverity("error");
        setToastState(true);
    })
    setLoading(false)
  }

  function getLoanSteps() {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/loans/${user?.user_id}/loan-steps/`
      )
      .then(function (response) {
        // console.log(response)
        setLatestStep(response?.data[0]);
        setProgress(25 * loanSteps[response?.data[0]?.loan_application_step]);
      })
      .catch(function (error) {
        setToastMessage(`${error.message}`);
        setToastSeverity("error");
        setToastState(true);
      });
  }

  React.useEffect(() => {
    getSureties();
    applications?.date_created &&
      !applications?.date_approved &&
      getLoanSteps();
  }, []);

  React.useEffect(() => {
    getLoanSteps();
  }, [state]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {state && (
        <LoanApplication
          state={state}
          setState={setState}
          sureties={sureties}
          setLoading={setLoading}
          userData={userData}
        />
      )}
      <Typography sx={{ fontWeight: 500, fontSize: "2em" }}>
        Welcome, {userData?.first_name && userData?.first_name}
      </Typography>
      <Box sx={{ marginTop: "3rem" }}>
        <Grid container spacing={{ xs: 5, xl: 10 }}>
          <Grid item xs={12} md={6} xl={4}>
            <Card
              variant={"outlined"}
              sx={{
                aspectRatio: { xs: "1.5/1", sm: "2.25/1", xl: "2/1" },
                width: "100%",
                borderColor: theme.palette.secondary.main,
              }}
            >
              <CardActionArea
                sx={{
                  height: "100%",
                  p: { xs: "0.25em", md: "0.5em", xl: "2em" },
                }}
                // onClick={() => setState(true)}
                // disabled={disabled}
              >
                <CardContent
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "50%",
                        md: "80%",
                        lg: "60%",
                        xl: "50%",
                      },
                    }}
                  >
                    <Typography
                      variant={"h3"}
                      sx={{ fontSize: { xs: "2rem" } }}
                    >
                      Apply for loan
                    </Typography>
                    <Typography
                      sx={{
                        color: "#858585",
                        fontSize: { xs: "1rem", sm: "1.25rem", xl: "1.5rem" },
                        mt: 1,
                      }}
                    >
                      Get financing for the properties of your dreams
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      position: "relative",
                      bottom: 0,
                      right: 0,
                    }}
                  >
                    <StyledButton
                      variant={"contained"}
                      size={"large"}
                      onClick={() => setState(true)}
                      disabled= {userData?.on_loan ? true : false}
                      sx={{
                        height: { xs: "initial", xl: "4em" },
                        width: { xs: "initial", xl: "12em" },
                        fontWeight: 500,
                        fontSize: "large",
                      }}
                    >
                      Apply for credit
                    </StyledButton>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          {disabled && (
            <Grid item xs={12} md={6} xl={4}>
              <Card
                variant={"outlined"}
                sx={{
                  aspectRatio: { xs: "1.5/1", sm: "2.25/1", xl: "2/1" },
                  width: "100%",
                  borderColor: theme.palette.secondary.main,
                }}
              >
                <CardActionArea
                  sx={{
                    height: "100%",
                    p: { xs: "0.25em", md: "0.5em", xl: "2em" },
                  }}
                  disabled
                >
                  <CardContent
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      padding: { xs: "0.5rem", xl: "1rem" },
                    }}
                  >
                    <LoanProgress latestStep={latestStep} progress={progress} />
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          )}
          {progress >= 50 && (
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: 500, fontSize: "1.5em", mb: 2 }}>
                  Loan Schedule
                </Typography>
                {progress>=50 && progress<75 && <StyledButton color={"primary"} variant={"contained"} size={"large"} onClick={downloadBond} disabled={loading} sx={{mb:1}}>
                    Download Loan Bond
                </StyledButton>}
              </Box>
              <LoanSchedule
                latestStep={latestStep}
                setLoading={setLoading}
                userData={userData}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default Loans;
