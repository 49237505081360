import axios from "axios";
import React from "react";
import AuthContext from "../../Context/AuthContext";


const UserData = () => {
    const [userData, setUserData] = React.useState({});
    const { user, setToastMessage, setToastState, setToastSeverity } = React.useContext(AuthContext);

    function getUserData () {
        axios
            .get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/account/dashboard/${user?.user_id}/`)
            .then(function (response) {
                setUserData(response.data);
            })
            .catch(function(error) {
                !(error.response?.data) &&
                    setToastMessage(`${error.message}`);
                    setToastSeverity("error");
                    setToastState(true);
            })
    }

    React.useEffect(() => {
        getUserData();
    },[])

    return userData
}

export default UserData;