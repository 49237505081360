import React from "react";
import Grid from "@mui/material/Grid";

import StyledTextField from "../../../../../../../../Components/common/Inputs";
import {PhoneNumberFormat} from "../../../../../../../../HelperFunctions/PhoneNumberFormat";


const RefereeOne = ({referee, refereeError, dataInputChange, editable}) => {
    return (
        <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
                <StyledTextField required label={"Full name"}  sx={{mt: 2}} name={"referee_name"}
                                 onChange={dataInputChange} error={!!refereeError[0].referee_nameError}
                                 helperText={!!refereeError[0].referee_nameError && refereeError[0].referee_nameError}
                                 value={referee.referee_name} disabled={!editable}/>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <StyledTextField label={"Email address"} type={"email"}
                                 sx={{mt: 2}} name={"referee_email"}
                                 onChange={dataInputChange} error={!!refereeError[0].referee_emailError}
                                 helperText={!!refereeError[0].referee_emailError ? refereeError[0].referee_emailError : ""}
                                 value={referee.referee_email} disabled={!editable}/>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
                <StyledTextField required label={"Telephone"} sx={{mt: 2}}
                                 InputProps={{
                                     inputComponent: PhoneNumberFormat,
                                 }} name={"referee_telephone"}
                                 onChange={dataInputChange} error={!!refereeError[0].referee_telephoneError}
                                 helperText={!!refereeError[0].telephoneError ? refereeError[0].telephoneError : ""}
                                 value={referee.referee_telephone} disabled={!editable}/>
            </Grid>
            <Grid item xs={12} md={6}>
                <StyledTextField required label={"Society"}  sx={{mt: 2}} name={"referee_society"}
                                 onChange={dataInputChange} error={!!refereeError[0].referee_societyError}
                                 helperText={!!refereeError[0].referee_societyError ? refereeError[0].referee_societyError : ""}
                                 value={referee.referee_society} disabled={!editable}/>
            </Grid>
            <Grid item xs={12} md={6}>
                <StyledTextField required label={"Position"}  sx={{mt: 2}} name={"referee_position"}
                                 onChange={dataInputChange} error={!!refereeError[0].referee_positionError}
                                 helperText={!!refereeError[0].referee_positionError ? refereeError[0].referee_positionError : ""}
                                 value={referee.referee_position} disabled={!editable}/>
            </Grid>
        </Grid>
    )
}

const RefereeTwo = ({referee, refereeError, dataInputChange, editable}) => {
    return (
        <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
                <StyledTextField required label={"Full name"}  sx={{mt: 2}} name={"referee_name"}
                                 onChange={dataInputChange} error={!!refereeError[1].referee_nameError}
                                 helperText={!!refereeError[1].referee_nameError && refereeError[1].referee_nameError}
                                 value={referee.referee_name} disabled={!editable}/>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <StyledTextField label={"Email address"} type={"email"}
                                 sx={{mt: 2}} name={"referee_email"}
                                 onChange={dataInputChange} error={!!refereeError[1].referee_emailError}
                                 helperText={!!refereeError[1].referee_emailError ? refereeError[1].referee_emailError : ""}
                                 value={referee.referee_email} disabled={!editable}/>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
                <StyledTextField required label={"Telephone"} sx={{mt: 2}}
                                 InputProps={{
                                     inputComponent: PhoneNumberFormat,
                                 }} name={"referee_telephone"}
                                 onChange={dataInputChange} error={!!refereeError[1].referee_telephoneError}
                                 helperText={!!refereeError[1].telephoneError ? refereeError[1].telephoneError : ""}
                                 value={referee.referee_telephone} disabled={!editable}/>
            </Grid>
            <Grid item xs={12} md={6}>
                <StyledTextField required label={"Society"}  sx={{mt: 2}} name={"referee_society"}
                                 onChange={dataInputChange} error={!!refereeError[1].referee_societyError}
                                 helperText={!!refereeError[1].referee_societyError ? refereeError[1].referee_societyError : ""}
                                 value={referee.referee_society} disabled={!editable}/>
            </Grid>
            <Grid item xs={12} md={6}>
                <StyledTextField required label={"Position"}  sx={{mt: 2}} name={"referee_position"}
                                 onChange={dataInputChange} error={!!refereeError[1].referee_positionError}
                                 helperText={!!refereeError[1].referee_positionError ? refereeError[1].referee_positionError : ""}
                                 value={referee.referee_position} disabled={!editable}/>
            </Grid>
        </Grid>
    )
}


export {RefereeOne, RefereeTwo}