import { Box } from '@mui/material'
import React from 'react'
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {styled} from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {TablePagination} from "@mui/material";
import dayjs from "dayjs";
import StyledLink from '../../../../../../Components/common/Link';


const columns= [
    {id: 'transaction', label: 'Transaction', minWidth: 250},
    {id: 'actual', label: 'Amount', minWidth: 150, align: 'right', format: (value)=> `U+20A6${value.toFixed(2)}`},
    {id: 'transaction type', label: 'Transaction type', minWidth: 170},
    {id: 'deposit type', label: 'Deposit type',minWidth: 170},
    {id: 'date', label: 'Date',minWidth: 170},
    {id: 'time', label: 'Time',minWidth: 170},
    {id: 'download', label: '',minWidth: 100},
]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#9b3f8317',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const TransactionsTable = ({transactionData}) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Box sx={{width: "100%"}}>
            <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <StyledTableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth, fontWeight:"bold" }}
                            >
                                {column.label}
                            </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {transactionData && transactionData.map((row) => (
                        <StyledTableRow key={row.name}>
                            <StyledTableCell component="th" scope="row">
                                {row.description}
                            </StyledTableCell>
                            <StyledTableCell align="right">{row.actual_amount}</StyledTableCell>
                            <StyledTableCell>{row.transaction_type}</StyledTableCell>
                            <StyledTableCell>{row.deposit_type}</StyledTableCell>
                            <StyledTableCell>{dayjs(row.created_date).format("ddd, DD MMM YYYY")}</StyledTableCell>
                            <StyledTableCell>{dayjs(row.created_date).format("hh:mm a")}</StyledTableCell>
                            <StyledTableCell align="right"><StyledLink>Download</StyledLink></StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
            </TableContainer>
            <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={transactionData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    )
}
