import Grid from "@mui/material/Grid";
import StyledTextField from "../../../../Components/common/Inputs";
import {PhoneNumberFormat} from "../../../../HelperFunctions/PhoneNumberFormat";
import Box from "@mui/material/Box";
import React from "react";
import StyledButton from "../../../../Components/common/Buttons";


const Form1 = ({dataInputChange, memberData, memberError, handleBack, handleNext}) => {
    return (
        <Box component="form" noValidate autoComplete="off" id={"form1"}>
            <Grid container sx={{mt: 3}} spacing={1}>
                <Grid item xs={12}>
                    <StyledTextField required label={"Email address"} type={"email"}
                                     sx={{mt: 2}}
                                     name={"email"}
                                     onChange={dataInputChange} error={!!memberError.emailError}
                                     helperText={!!memberError.emailError ? memberError.emailError : ""}
                                     value={memberData?.email}/>
                </Grid>
                <Grid item xs={12}>
                    <StyledTextField required label={"Telephone"} sx={{mt: 2}}
                                     InputProps={{
                                         inputComponent: PhoneNumberFormat,
                                     }} name={"telephone"} placeholder={"+2348xxxxxxxxx"}
                                     onChange={dataInputChange} error={!!memberError.telephoneError}
                                     helperText={!!memberError.telephoneError ? memberError.telephoneError : ""}
                                     value={memberData?.telephone}/>
                </Grid>
            </Grid>
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 10
            }}>
                <StyledButton variant={"text"} size={"large"} sx={{ml: "-8px"}}
                              onClick={handleBack}>
                    Back
                </StyledButton>
                <StyledButton variant={"contained"} size={"large"} onClick={handleNext}>
                    Next
                </StyledButton>
            </Box>
        </Box>
    )
}

export default Form1;