import Grid from "@mui/material/Grid";
import StyledTextField from "../../../../Components/common/Inputs";
import Box from "@mui/material/Box";
import React from "react";
import StyledButton from "../../../../Components/common/Buttons";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {FormControl, FormHelperText} from "@mui/material";


const Form3 = ({dataInputChange, memberData, memberError, dataFilesChange, handleBack, handleNext}) => {

    function openPictureDialog() {
        document.getElementById("pictureInput").click();
    }

    return (
        <Box component="form" noValidate autoComplete="off" id={"form3"}>
            <Grid container sx={{mt: 3}} spacing={1}>
                <Grid item xs={12}>
                    <StyledTextField required label={"Occupation"} sx={{mt: 2}}
                                     name={"occupation"}
                                     onChange={dataInputChange}
                                     value={memberData?.occupation} error={!!memberError.occupationError}
                                     helperText={!!memberError.occupationError ? memberError.occupationError : ""}/>
                </Grid>
                <Grid item xs={12}>
                    {/*<LocalizationProvider dateAdapter={AdapterDayjs}>*/}
                    {/*    <DatePicker*/}
                    {/*        required label={"Date of birth"}*/}
                    {/*        openTo={"year"}*/}
                    {/*        views={["year", "month", "day"]}*/}
                    {/*        sx={{mt: 2, width:'100%'}} name={"date_of_birth"}*/}
                    {/*        onChange={(newValue) => setMemberData((prevState) => ({*/}
                    {/*            ...prevState,*/}
                    {/*            date_of_birth: (newValue['$d']).getDate()*/}
                    {/*        }))}*/}
                    {/*        format={"DD/MMMM/YYYY"}*/}
                    {/*        value={memberData?.date_of_birth}*/}
                    {/*        disableFuture*/}
                    {/*        slotProps={{*/}
                    {/*            textField: {*/}
                    {/*                helperText: !!memberError.DOBError ? memberError.DOBError : "",*/}
                    {/*            },*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*</LocalizationProvider>*/}
                    <StyledTextField focused required label={"Date of birth"}
                                     type={"date"}
                                     sx={{mt: 2}} name={"date_of_birth"}
                                     onChange={dataInputChange}
                                     value={memberData?.date_of_birth} error={!!memberError.DOBError}
                                     helperText={!!memberError.DOBError ? memberError.DOBError : ""}/>
                </Grid>
                <Grid item xs={12}>
                    <StyledButton size={"large"} fullWidth variant={"contained"}
                                  sx={{mt: 2, height: "3em"}}
                                  startIcon={<CloudUploadIcon/>}
                                  onClick={openPictureDialog}>
                        {memberData?.profile_image
                            ? memberData?.profile_image?.name
                            : "Upload profile picture"}
                    </StyledButton>
                    <FormControl>
                        <input type={"file"}
                               accept={"image/*"} hidden id={"pictureInput"}
                               name={"profile_image"} onChange={dataFilesChange}/>
                        <FormHelperText error={!!memberError.profilePictureError}>{!!memberError.profilePictureError ? memberError.profilePictureError : ""}</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 10
            }}>
                <StyledButton variant={"text"} size={"large"} sx={{ml: "-8px"}}
                              onClick={handleBack}>
                    Back
                </StyledButton>
                <StyledButton variant={"contained"} size={"large"} onClick={handleNext}>
                    Next
                </StyledButton>
            </Box>
        </Box>
    )
}

export default Form3;