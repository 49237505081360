import Box from "@mui/material/Box";
import React from "react";
import {styled} from "@mui/material/styles";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import dayjs from "dayjs";
import {TablePagination} from "@mui/material";
import AuthContext from "../../../../../../Context/AuthContext";
import useAxios from "../../../../../../utils/useAxios";
import StyledButton from "../../../../../../Components/common/Buttons";
import LoanRepayment from "./LoanRepayment";
import userData from "../../../../../../HelperFunctions/UserData";

const columns = [
    { id: 'month', label: 'Month', minWidth: 100 },
    { id: 'loanAmount',
        label: 'Loan Amount',
        minWidth: 200,
        align: 'center',
        format: (value) => `U+20A6${value.toFixed(2)}`},
    { id: 'monthlyPrincipalRepayment',
        label: 'Principal Repayment',
        minWidth: 200,
        align: 'center',
        format: (value) => `U+20A6${value.toFixed(2)}`},
    { id: 'interestCharges',
        label: 'Interest Charges',
        minWidth: 200,
        align: 'center',
        format: (value) => `U+20A6${value.toFixed(2)}`},
    { id: 'loanAmountBalance',
        label: 'Loan Amount Balance',
        minWidth: 200,
        align: 'center',
        format: (value) => `U+20A6${value.toFixed(2)}`},
    {
        id: 'repaymentDate',
        label: 'Repayment Date',
        align: 'center',
        minWidth: 150,
    },
    {
        id: 'payPrincipal',
        label: '',
        minWidth: 100,
    },
    {
        id: 'payInterest',
        label: '',
        minWidth: 100,
    },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#9b3f8317',
    },
}));


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const LoanSchedule = ({latestStep, setLoading}) => {
    const [state, setState] = React.useState(false)
    const { user, setToastMessage, setToastSeverity, setToastState } = React.useContext(AuthContext);
    const axios = useAxios();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [schedule, setSchedule] = React.useState([]);
    const [amount, setAmount] = React.useState(0);
    const [month, setMonth] = React.useState(0);
    const [scheduleId, setScheduleId] = React.useState("");
    const [title, setTitle] = React.useState("");
    const [repaymentType, setRepaymentType]= React.useState("");

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function payRepayment(item, amount, title, repaymentType) {
        setAmount(amount)
        setMonth(item.month)
        setScheduleId(item.id)
        setTitle(title)
        setRepaymentType(repaymentType)
        setState(true)
    }

    function getSchedule() {
        axios
            .get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/loans/${user.user_id}/applicant/loan-schedule/${latestStep?.loan_key}/`)
            .then(function (res) {
                setSchedule(res.data)
            })
            .catch(function (err) {
                !(err.response) &&
                setToastMessage(`${err.message}`);
                setToastSeverity("error");
                setToastState(true);
            })
    }
    React.useEffect(()=>{
        getSchedule();
    }, [])

    return (
        <>
            { state && <LoanRepayment loanStep={latestStep} userData={userData} state={state} setState={setState} setLoading={setLoading} month={month} amount={amount} title={title} repaymentType={repaymentType} schedule_id={scheduleId} /> }
        <Box sx={{width: "100%"}}>
            <TableContainer sx={{ maxHeight: "50vh" }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <StyledTableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, fontWeight:"bold" }}
                                >
                                    {column.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {schedule && schedule.map((row) => (
                            <StyledTableRow key={row.month}>
                                <StyledTableCell component="th" scope="row">
                                    {row.month}
                                </StyledTableCell>
                                <StyledTableCell align="center">&#8358;{row.loan_amount}</StyledTableCell>
                                <StyledTableCell align='center'>&#8358;{row.monthly_principal_repayment}</StyledTableCell>
                                <StyledTableCell align='center'>&#8358;{row.interest_charges}</StyledTableCell>
                                <StyledTableCell align='center'>&#8358;{row.loan_amount_balance}</StyledTableCell>
                                <StyledTableCell align='center'>{dayjs(row.date_of_repayment).format("ddd, DD MMM YYYY")}</StyledTableCell>
                                <StyledTableCell align="right"><StyledButton onClick={()=>payRepayment(row, row.monthly_principal_repayment,'Principal', 'LOA')} disabled={row.principal_repaid} variant={"text"}>Pay Principal</StyledButton></StyledTableCell>
                                <StyledTableCell align="right"><StyledButton onClick={()=>payRepayment(row, row.interest_charges, 'Interest', 'INT')} disabled={row.interest_paid} variant={"text"}>Pay Interest</StyledButton></StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={schedule?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
        </Box>
        </>
    )
}

export default LoanSchedule;