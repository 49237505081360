import React from "react";
import PropTypes from "prop-types";
import LinearProgress from '@mui/material/LinearProgress';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";

const steps = [
    {id:1, description:"Loan application"},
    {id:2, description:"Attend interview and submit necessary documents"},
    {id:3, description:"Fill Loan Bond"},
    {id:4, description:"Confirm repayment schedule"},
]

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="buffer" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary" fontWeight={600}>{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};


const LoanProgress = ({latestStep, progress}) => {

    return (
        <Box sx={{display:"flex", flexDirection:"column", justifyContent:"space-between", height:"100%"}}>
            <Box>
                <Typography sx={{width:{xs:"100%", md:"60%", lg:"100%", xl:"50%"}, fontSize:{xs:"1rem", md:"1.5rem", xl:"2rem"}, fontWeight:{xs:600, lg:500} }}>
                    Your application is under review.
                </Typography>
                <List>
                    {steps.map((step)=>(
                        <ListItem key={step.id} disablePadding secondaryAction={
                            <IconButton edge="end" aria-label="delete">
                                <Checkbox
                                    edge="end"
                                    disabled
                                    checked={step.id <= progress/25}
                                    inputProps={{ 'aria-labelledby': step.description }}
                                />
                            </IconButton>
                        }>
                            <ListItemText primary={step.description} />
                        </ListItem>
                    ))}
                </List>
            </Box>
            <LinearProgressWithLabel value={progress} color={"secondary"} size={150} valueBuffer={progress < 100 ? progress+5 : progress} />
        </Box>
    )
}

export default LoanProgress;