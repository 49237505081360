import {styled} from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer"

const StyledDrawer = styled((props) => (
    <SwipeableDrawer {...props} />
))(({theme}) => ({
    "& .MuiPaper-root": {
        width: "20%",
        padding:"2em",
        zIndex: 1202,
        borderLeft: "1px solid #d1d1d1",
        [theme.breakpoints.down('xl')]: {
            width: "40%",
        },
        [theme.breakpoints.down('lg')]: {
            width: "45%",
        },
        [theme.breakpoints.down('md')]: {
            width: "60%",
        },
        [theme.breakpoints.down('sm')]: {
            width: "80%"
        },
    },
    "&:hover, &:focus": {
        boxShadow: "rgba(0, 0, 0, 0.15) 2px 3px 10px -2px, rgba(0, 0, 0, 0.09) 1px 2px 10px 0px, rgba(0, 0, 0, 0.07) 1px 1px 20px 0px;"
    }
}))

export default StyledDrawer;