import React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";

import StyledLink from "../../Link";
import StyledButton from "../../Buttons";
import {useHistory} from "react-router-dom";
import ChevronRight from "@mui/icons-material/ChevronRight";
import {KeyboardArrowDown} from "@mui/icons-material";


const Account = ({handleOpenUserMenu, anchorElUser, handleCloseUserMenu, settings, user, userData, logoutUser}) => {
    const history = useHistory();

    function handleLink(link) {
        link==='' ? logoutUser() : history.push(link);
    }

    return (
        <>
            {user ? (
                <Box sx={{ flexGrow: 0 }}>
                    <Tooltip title="Account options">
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar alt={`${userData?.first_name} ${userData?.last_name}`} src={`${process.env.REACT_APP_BACKEND_ENDPOINT}${userData?.profile_image}`} />
                            {!!anchorElUser ? <KeyboardArrowDown /> : <ChevronRight /> }
                        </IconButton>
                    </Tooltip>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        keepMounted
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgColor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        {settings.filter(setting => {
                            return !(!userData?.is_staff && setting.id === 2);
                        }).map(setting => (
                            <MenuItem key={setting.id} onClick={() => handleLink(setting.link)}>
                                <StyledLink color={setting.title==='Logout' ? 'error' : "secondary"} textAlign="center">{setting.title}</StyledLink>
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
            ) : (
                <Box sx={{display: "flex", textWrap: 'nowrap'}}>
                    <StyledButton color={"secondary"} size={"large"} sx={{fontSize: "1em", mx: {xs: 0, md: 2}}} onClick={()=>history.push('/account/login')}>
                        Log in
                    </StyledButton>
                    <StyledButton variant={"contained"} size={"large"} color={"secondary"} sx={{fontSize: "1em", mx: {xs: 0, md: 2} }} onClick={()=>history.push('/account/register')}>
                        Sign up
                    </StyledButton>
                </Box>
            )}
        </>

    )
}

export default Account