import React from "react";
import Card from "@mui/material/Card";
import {CardActionArea, CardContent, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";


const Balance = ({userData, userWallet}) => {
    const theme = useTheme();
    return (
        <Grid container spacing={{xs:5, xl:10}}>
            <Grid item xs={12} sm={6} md={4} xl={2}>
                <Card variant={"outlined"} sx={{aspectRatio:{xs:"1.5/0.75", sm:"2.25/1", xl:"2/1"}, width:"100%", backgroundColor:theme.palette.primary.main, color:"#fff"}}>
                    <CardActionArea sx={{height:"100%", p: {xs:"1em", md:"0.5em", xl:"1em"}}}>
                        <CardContent >
                            <Typography sx={{fontWeight:600, fontSize: {xs:"1.5em", md:"1em", xl:"1.5em"}}}>Wallet Account Details</Typography>
                            <Typography sx={{mt: {xs:0.25, xl:1}}}>{userWallet?.bank_name}</Typography>
                            <Typography sx={{mt: {xs:0.25, xl:1}}}>{userWallet?.account_name}</Typography>
                            <Typography sx={{mt: {xs:0.25, xl:1}, fontWeight:800, fontSize: {xs:"2em", md:"1.5em", xl:"3.5em"}}}>{userWallet?.account_number}</Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={2}>
                <Card variant={"outlined"} sx={{aspectRatio:{xs:"1.5/0.75", sm:"2.25/1", xl:"2/1"}, width:"100%", backgroundColor:theme.palette.secondary.main, color:"#fff"}}>
                    <CardActionArea sx={{height:"100%", p: {xs:"1em", md:"0.5em", xl:"1em"}}}>
                        <CardContent >
                            <Typography sx={{fontWeight:600, fontSize: {xs:"1.5em", md:"1em", xl:"1.5em"}}}>Wallet</Typography>
                            <Typography sx={{mt: {xs:0.25, md:0, xl:1}}}>Available balance</Typography>
                            <Typography sx={{mt: {xs:1, md:0, xl:2}, fontWeight:800, fontSize: {xs:"2em", md:"1.5em", xl:"3.5em"}}}>
                                &#8358;{userData?.wallet_balance}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={2}>
                <Card variant={"outlined"} sx={{aspectRatio:{xs:"1.5/0.75", sm:"2.25/1", xl:"2/1"}, width:"100%"}}>
                    <CardActionArea sx={{height:"100%", p: {xs:"1em", md:"0.5em", xl:"1em"}}}>
                        <CardContent >
                            <Typography sx={{fontWeight:600, fontSize: {xs:"1.5em", md:"1em", xl:"1.5em"}}}>Wallet</Typography>
                            <Typography sx={{mt: {xs:0.25, md:0, xl:1}}}>Total deposits</Typography>
                            <Typography sx={{mt: {xs:1, md:0, xl:2}, fontWeight:800, fontSize: {xs:"2em", md:"1.5em", xl:"3.5em"}, color:theme.palette.secondary.main}}>
                                &#8358;{userData?.total_deposits}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={2}>
                <Card variant={"outlined"} sx={{aspectRatio:{xs:"1.5/0.75", sm:"2.25/1", xl:"2/1"}, width:"100%"}}>
                    <CardActionArea sx={{height:"100%", p: {xs:"1em", md:"0.5em", xl:"1em"}}}>
                        <CardContent >
                            <Typography sx={{fontWeight:600, fontSize: {xs:"1.5em", md:"1em", xl:"1.5em"}}}>Shares</Typography>
                            <Typography sx={{mt: {xs:0.5, md:0, xl:1}}}>Available balance</Typography>
                            <Typography sx={{mt: {xs:1, md:0, xl:2}, fontWeight:800,  fontSize: {xs:"2em", md:"1.5em", xl:"3.5em"}, color:theme.palette.secondary.main}}>
                                &#8358;{userData?.total_shares}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={2}>
                <Card variant={"outlined"} sx={{aspectRatio:{xs:"1.5/0.75", sm:"2.25/1", xl:"2/1"}, width:"100%"}}>
                    <CardActionArea sx={{height:"100%", p: {xs:"1em", md:"0.5em", xl:"1em"}}}>
                        <CardContent >
                            <Typography sx={{fontWeight:600, fontSize: {xs:"1.5em", md:"1em", xl:"1.5em"}}}>Extra savings</Typography>
                            <Typography sx={{mt: {xs:0.5, md:0, xl:1}}}>Available balance</Typography>
                            <Typography sx={{mt: {xs:1, md:0, xl:2}, fontWeight:800,  fontSize: {xs:"2em", md:"1.5em", xl:"3.5em"}, color:theme.palette.secondary.main}}>
                                &#8358;{userData?.total_extrasavings}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={2}>
                <Card variant={"outlined"} sx={{aspectRatio:{xs:"1.5/0.75", sm:"2.25/1", xl:"2/1"}, width:"100%"}} disabled>
                    <CardActionArea sx={{height:"100%", p: {xs:"1em", md:"0.5em", xl:"1em"}}}>
                        <CardContent >
                            <Typography sx={{fontWeight:600, fontSize: {xs:"1.5em", md:"1em", xl:"1.5em"}}}>Others</Typography>
                            <Typography sx={{mt: {xs:0.5, md:0, xl:1}}}>Available balance</Typography>
                            <Typography sx={{mt: {xs:1, md:0, xl:2}, fontWeight:800,  fontSize: {xs:"2em", md:"1.5em", xl:"3.5em"}, color:theme.palette.secondary.main}}>
                                &#8358;{userData?.total_other_payments}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
        </Grid>
    )
}

export default Balance