import React from "react";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AuthContext from "../../../Context/AuthContext";
import Slide from '@mui/material/Slide';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

const StyledToastBar = () => {
    const { toastState, setToastState, toastMessage, toastSeverity } = React.useContext(AuthContext);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setToastState(false);
    };
    return (
        <Snackbar open={toastState} autoHideDuration={5000} onClose={handleClose} sx={{zIndex:2001}}
                  TransitionComponent={TransitionUp} anchorOrigin={{vertical:'top', horizontal:'right'}}>
            <Alert onClose={handleClose} severity={toastSeverity}
                   sx={{ width: '100%', fontSize:"1.15em", alignItems:"center",
                        "& .MuiAlert-action":{
                            padding: "0 0 0 16px"
                        } }}>
                {toastMessage}
            </Alert>
        </Snackbar>
    )
}


export default StyledToastBar;