import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Header from "../../Components/common/Header";
import StyledButton from "../../Components/common/Buttons";
import partner from "../../Assets/img/partners.png";
import woman from "../../Assets/img/woman.png";
import family from "../../Assets/img/family.png";
import handover from "../../Assets/img/handover.png";
import {useHistory} from "react-router-dom";


const settings = {
    dots: true,
    infinite: true,
    fade: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear"
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
};

const Homepage = () => {
    const history = useHistory()
    return (
        <>
            <Header />
            <Box sx={{display: "flex", alignItems: "center", height: "95vh", mt: {xs: 15, sm: 12, md:40, lg:0}, mb:{xs:8, sm:0} }}>
                <Grid container sx={{alignItems: "center"}}>
                    <Grid item xs={12} lg={6}>
                        <Box sx={{display: "flex", flexDirection: "column", px: {xs: 5, sm: "3em", md:"10em", lg:"8em", xl:"25em"}, textAlign:{xs:"center", lg:"left"}, pt:{lg:"7em"} }}>
                            <Typography variant={"h4"} sx={{fontWeight: 500}}>
                                OGSCOFED Branch 1 <br /> Cooperative Investment and Housing Society.
                                {/*OGSCOFED <br /> Cooperative Housing.*/}
                            </Typography>
                            <Typography sx={{mt:2, fontSize: "1em"}}>
                                A collaborative initiative in which individuals and families come together to
                                collectively own and manage residential properties.
                            </Typography>
                            <Box sx={{display: "flex", alignItems: "center", mt: 5}}>
                                <StyledButton sx={{width: "-webkit-fill-available"}}
                                              color={"secondary"} size={"large"}
                                              variant={"contained"}
                                              onClick={()=>history.push('/account/register')}>
                                    Join now
                                </StyledButton>
                                <StyledButton sx={{ml:2}} size={"large"} color={"secondary"}
                                              variant={"outlined"} onClick={()=>history.push('/account/login')}>
                                    Login
                                </StyledButton>
                            </Box>
                            <Divider sx={{my:3}} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Box sx={{display: "flex", flexDirection:"column", px: {xs: "5em", sm: "5em", md:"10em", xl:"25em"}}}>
                        <Slider {...settings}>
                            <Box sx={{aspectRatio: "1/1", width: "25em"}}>
                                <img src={family} width={"100%"} alt={"OGSCOFED Family illustration"}/>
                                <Typography sx={{mt:-5, mb:2, fontSize: "1em", textAlign: "center"}}>
                                    This housing is more accessible to a wider range of people, including those who might not be able to afford a single-family home or apartment on their own.
                                </Typography>
                            </Box>
                            <Box sx={{aspectRatio: "1/1", width: "25em"}}>
                                <img src={woman} width={"100%"} alt={"OGSCOFED woman illustration"}/>
                                <Typography sx={{mt:-5, mb:2, fontSize: "1em", textAlign: "center"}}>
                                    Members often contribute their time and effort to tasks like property maintenance, gardening, and community events.
                                </Typography>
                            </Box>
                            <Box sx={{aspectRatio: "1/1", width: "25em"}}>
                                <img src={partner} width={"100%"} alt={"OGSCOFED partners illustration"}/>
                                <Typography sx={{mt:-5, mb:2, fontSize: "1em", textAlign: "center"}}>
                                    Members purchase shares or memberships, rather than traditional homeownership, giving them a say in the governance and decision-making processes of the community.
                                </Typography>
                            </Box>
                            <Box sx={{aspectRatio: "1/1", width: "25em"}}>
                                <img src={handover} width={"100%"} alt={"OGSCOFED handover illustration"}/>
                                <Typography sx={{mt:-5, mb:2, fontSize: "1em", textAlign: "center"}}>
                                    Residents often have a close-knit relationship with their neighbors, resulting in a supportive and collaborative living environment.
                                </Typography>
                            </Box>
                        </Slider>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default Homepage;