import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import NaijaStates from "naija-state-local-government";
import {useHistory} from "react-router-dom";

import StyledButton from "../../../Components/common/Buttons";
import StyledCard from "../../../Components/common/Card";
import logo from "../../../Assets/img/logo.png";
import StyledLink from "../../../Components/common/Link";
import Loader from "../../../Components/common/Loader";
import AuthContext from "../../../Context/AuthContext";
import StyledAlert from "../../../Components/common/Alert";
import Header from "../../../Components/common/Header";
import Form0 from "./components/form0";
import Form1 from "./components/form1";
import Form2 from "./components/form2";
import Form3 from "./components/form3";
import Form4 from "./components/form4";


const Registration = () => {
    const { registerUser } = React.useContext(AuthContext);
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);

    const allStates = NaijaStates.states();
    const [allCities, setAllCities] = React.useState(null);

    const [memberData, setMemberData] = React.useState({
        first_name: "",
        other_name: "",
        last_name: "",
        telephone: "",
        email: "",
        occupation: "",
        date_of_birth: "",
        address: "",
        states: "",
        city: "",
        password: "",
        password2: "",
        profile_image: "",
    })

    const [memberError, setMemberError] = React.useState({
        error: false,
        errorMessage: "",
        firstNameError: "",
        lastNameError: "",
        otherNameError: "",
        telephoneError: "",
        emailError: "",
        occupationError: "",
        DOBError: "",
        addressError: "",
        statesError: "",
        cityError: "",
        passwordError: "",
        password2Error: "",
        profilePictureError: "",
    })

    const dataInputChange = (e) => {
        if (e.target.name === 'states') {
            setAllCities(NaijaStates.lgas(e.target.value)["lgas"])
        }
        setMemberData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const dataFilesChange = (e) => {
        setMemberData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.files[0]
        }))
    }

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    // const handleReset = () => {
    //     setActiveStep(0);
    // };

    const handleSubmit = () => {
        // form = document.getElementById(`form${activeStep}`);
        // if (!form.reportValidity()) {
        //     return;
        // }
        setMemberError({
            error: false,
            firstNameError: "",
            lastNameError: "",
            otherNameError: "",
            telephoneError: "",
            emailError: "",
            occupationError: "",
            DOBError: "",
            addressError: "",
            statesError: "",
            cityError: "",
            passwordError: "",
            password2Error: "",
            profilePictureError: "",
        })
        registerUser(memberData, setMemberError, setLoading)
    }

    // React.useEffect(())

    return (
        <>
            {loading && <Loader loading={loading}/>}
            <Header />
            <Box sx={{height: "95vh", mt: {xs: 10, sm: 0}, width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap"}}>
                <Box sx={{
                    width: {xs: "90%", sm: "50%", md: "30%", lg: "20%"},
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center"
                }}>
                    <StyledCard variant={"outlined"} sx={{p: 3}}>
                        <Box
                            sx={{
                                fontWeight: 900,
                                textAlign: "center",
                                width: "20%",
                                display: "flex",
                                alignSelf: "center"
                            }}>
                            <img src={logo} alt={"OGSCOFED logo"} width={"100%"}/>
                        </Box>
                        <Typography sx={{fontWeight: 600, mt: 3, textAlign: "center"}}>
                            Register <br/><span style={{fontWeight: 400}}>to continue to OGSCOFED HOUSING</span>
                        </Typography>
                        {memberError.error && <StyledAlert opened={memberError.error} />}

                        {activeStep === 0 ? (
                            // Personal information
                            <Form0 dataInputChange={dataInputChange} memberData={memberData} memberError={memberError} handleNext={handleNext} />
                        ) : (
                            activeStep === 1 ? (
                                // contact info
                                <Form1 dataInputChange={dataInputChange} memberData={memberData} memberError={memberError} handleNext={handleNext} handleBack={handleBack} />
                            ) : (
                                activeStep === 2 ? (
                                    // address info
                                    <Form2 dataInputChange={dataInputChange} memberData={memberData} memberError={memberError} allStates={allStates} allCities={allCities} handleNext={handleNext} handleBack={handleBack} />
                            ) : (
                                    activeStep === 3 ? (
                                        // verification
                                        <Form3 dataInputChange={dataInputChange} memberData={memberData} memberError={memberError} dataFilesChange={dataFilesChange} handleNext={handleNext} handleBack={handleBack} />
                                    ) : (
                                        // Authentication
                                        <Form4 dataInputChange={dataInputChange} memberData={memberData} memberError={memberError} handleSubmit={handleSubmit} handleBack={handleBack} loading={loading} />
                                    )
                                )
                            )
                        )}
                        <Typography sx={{fontWeight: 400, mt: 3, textAlign: "center"}}>
                            Already have an account? <StyledLink as={StyledButton}
                                                                 onClick={(e) => history.push("/account/login")}>Log
                            in</StyledLink>
                        </Typography>
                    </StyledCard>
                </Box>
            </Box>
        </>
    )
}

export default Registration;