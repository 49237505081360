import useAxios from "../../utils/useAxios";
import React from "react";
import AuthContext from "../../Context/AuthContext";


const GetLoanApplication = () => {
    const axios = useAxios();
    const [userApplications, setUserApplications] = React.useState(null);
    const { user, setToastMessage, setToastState, setToastSeverity } = React.useContext(AuthContext);
    function getUserData() {
        axios
            .get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/loans/${user.user_id}/apply/`)
            .then(function (response) {
                setUserApplications(response.data);
            })
            .catch(function(error) {
                !(error.response?.data) &&
                    setToastMessage(`${error.message}`);
                    setToastSeverity("error");
                    setToastState(true);
            })
    }

    React.useEffect(() => {
        getUserData();
    },[])

    return userApplications
}


export default GetLoanApplication