import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {blue, green, purple, yellow} from "@mui/material/colors";
import {GoArchive, GoCodeOfConduct, GoGraph, GoPeople} from "react-icons/go";


const Home = () => {
    return (
        <Box>
            <Box sx={{ backgroundColor:"#fff",
                borderRadius:"1rem", width:"100%", p:"2rem",
                marginTop:"2rem", display:"flex", flexDirection:"column",
                justifyContent:"space-between"}}>
                <Typography sx={{fontWeight:500, fontSize:"1.5rem"}}>Admin Analytics</Typography>
                <Grid container spacing={2} sx={{mt:"1rem"}}>
                    <Grid item xs={12} sm={6} lg={3} xl={2}>
                        <Box sx={{backgroundColor:green[100],
                            borderRadius:"0.75rem",
                            height: {xs:"8rem", md:"10rem", xl:"12rem"}, position:"relative"}}>
                            <Box sx={{position:"absolute", left:0, top:"15%",
                                height:"40%", width:"3%", backgroundColor:green[200]}}></Box>
                            <Box sx={{position:"absolute", left:0, top:"15%",
                                height:"40%", width:"1.5%", backgroundColor:green[300]}}></Box>
                            <Box sx={{position:"absolute", right:"1rem", top:"15%",
                                height:"40%", aspectRatio:"1/1", backgroundColor:green[200],
                                borderRadius:"0.25rem", padding:"1rem", display:"flex",
                                alignItems:"center", justifyContent:"center"}}>
                                    <GoPeople color={green[500]} fontSize={"2.5em"} />
                            </Box>
                            <Box sx={{position:"relative", left:"2rem", top:"2rem", bottom:"2rem",
                                right:"40.5%", display:"flex", flexDirection:"column", alignItems:"start",}}>
                                    <Typography sx={{color:"#78828E", fontWeight:300}}>
                                        All Users
                                    </Typography>
                                    <Typography sx={{color:"#0A0E1A", fontWeight:600, fontSize:"2em", mt:"0.5rem"}}>
                                        400
                                    </Typography>
                                    <Typography sx={{color:"#78828E", fontWeight:300}}>
                                        Unique coop members
                                    </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} xl={2}>
                        <Box sx={{backgroundColor:purple[100],
                            borderRadius:"0.75rem",
                            height:{xs:"8rem", md:"10rem", xl:"12rem"}, position:"relative"}}>
                            <Box sx={{position:"absolute", left:0, top:"15%",
                                height:"40%", width:"3%", backgroundColor:purple[200]}}></Box>
                            <Box sx={{position:"absolute", left:0, top:"15%",
                                height:"40%", width:"1.5%", backgroundColor:purple[300]}}></Box>
                            <Box sx={{position:"absolute", right:"1rem", top:"15%",
                                height:"40%", aspectRatio:"1/1", backgroundColor:purple[200],
                                borderRadius:"0.25rem", padding:"1rem", display:"flex",
                                alignItems:"center", justifyContent:"center"}}>
                                    <GoArchive color={purple[500]} fontSize={"2.5em"} />
                            </Box>
                            <Box sx={{position:"relative", left:"2rem", top:"2rem", bottom:"2rem",
                                right:"40.5%", display:"flex", flexDirection:"column", alignItems:"start",}}>
                                <Typography sx={{color:"#78828E", fontWeight:300}}>
                                    All Users
                                </Typography>
                                <Typography sx={{color:"#0A0E1A", fontWeight:600, fontSize:"2em", mt:"0.5rem"}}>
                                    400
                                </Typography>
                                <Typography sx={{color:"#78828E", fontWeight:300}}>
                                    Unique coop members
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} xl={2}>
                        <Box sx={{backgroundColor:yellow[100],
                            borderRadius:"0.75rem",
                            height:{xs:"8rem", md:"10rem", xl:"12rem"}, position:"relative"}}>
                            <Box sx={{position:"absolute", left:0, top:"15%",
                                height:"40%", width:"3%", backgroundColor:yellow[200]}}></Box>
                            <Box sx={{position:"absolute", left:0, top:"15%",
                                height:"40%", width:"1.5%", backgroundColor:yellow[300]}}></Box>
                            <Box sx={{position:"absolute", right:"1rem", top:"15%",
                                height:"40%", aspectRatio:"1/1", backgroundColor:yellow[200],
                                borderRadius:"0.25rem", padding:"1rem", display:"flex",
                                alignItems:"center", justifyContent:"center"}}>
                                <GoGraph color={yellow[500]} fontSize={"2.5em"} />
                            </Box>
                            <Box sx={{position:"relative", left:"2rem", top:"2rem", bottom:"2rem",
                                right:"40.5%", display:"flex", flexDirection:"column", alignItems:"start",}}>
                                <Typography sx={{color:"#78828E", fontWeight:300}}>
                                    All Users
                                </Typography>
                                <Typography sx={{color:"#0A0E1A", fontWeight:600, fontSize:"2em", mt:"0.5rem"}}>
                                    400
                                </Typography>
                                <Typography sx={{color:"#78828E", fontWeight:300}}>
                                    Unique coop members
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} xl={2}>
                        <Box sx={{backgroundColor:blue[100],
                            borderRadius:"0.75rem",
                            height:{xs:"8rem", md:"10rem", xl:"12rem"}, position:"relative"}}>
                            <Box sx={{position:"absolute", left:0, top:"15%",
                                height:"40%", width:"3%", backgroundColor:blue[200]}}></Box>
                            <Box sx={{position:"absolute", left:0, top:"15%",
                                height:"40%", width:"1.5%", backgroundColor:blue[300]}}></Box>
                            <Box sx={{position:"absolute", right:"1rem", top:"15%",
                                height:"40%", aspectRatio:"1/1", backgroundColor:blue[200],
                                borderRadius:"0.25rem", padding:"1rem", display:"flex",
                                alignItems:"center", justifyContent:"center"}}>
                                <GoCodeOfConduct color={blue[500]} fontSize={"2.5em"} />
                            </Box>
                            <Box sx={{position:"relative", left:"2rem", top:"2rem", bottom:"2rem",
                                right:"40.5%", display:"flex", flexDirection:"column", alignItems:"start",}}>
                                <Typography sx={{color:"#78828E", fontWeight:300}}>
                                    All Users
                                </Typography>
                                <Typography sx={{color:"#0A0E1A", fontWeight:600, fontSize:"2em", mt:"0.5rem"}}>
                                    400
                                </Typography>
                                <Typography sx={{color:"#78828E", fontWeight:300}}>
                                    Unique coop members
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default Home