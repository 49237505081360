import React from "react";
import Box from "@mui/material/Box";
import {useHistory} from "react-router-dom";
import Typography from "@mui/material/Typography";

import StyledButton from "../../Components/common/Buttons";
import StyledCard from "../../Components/common/Card";
import logo from "../../Assets/img/logo.png";
import StyledTextField from "../../Components/common/Inputs";
import Loader from "../../Components/common/Loader";
import axios from "axios";
import StyledAlert from "../../Components/common/Alert";
import Header from "../../Components/common/Header";
import AuthContext from "../../Context/AuthContext";


const ForgotPasswordVerify = () => {
    const {setToastMessage, setToastState, setToastSeverity} = React.useContext(AuthContext)
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);

    const [memberData, setMemberData] = React.useState({
        email: ""
    })

    const [memberError, setMemberError] = React.useState({
        error: false,
        errorMessage: "",
        emailError: "",
    })

    const dataInputChange = (e) => {
        setMemberData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = () => {
        setLoading(true)
        axios
            .post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/account/request-reset-email/`, memberData)
            .then(function (res) {
                setLoading(false);
                setToastMessage("Password reset email sent");
                setToastSeverity("success");
                setToastState(true);
                history.push("/account/password/reset/complete/:uidb64/:token");
            })
            .catch(function (error) {
                error.response &&
                    setMemberError({
                        error: true,
                        errorMessage: error.response.data.hasOwnProperty("detail") ? error.response.data["detail"] : "",
                        emailError: error.response.data.hasOwnProperty("email") ? error.response.data["email"] : "",
                    })

                !(error.response) &&
                    setToastMessage(`${error.message}`);
                    setToastSeverity("error");
                    setToastState(true);
                setLoading(false)
            })
    }
    return (
        <>
            {loading && <Loader loading={loading}/>}
            <Header />
            <Box sx={{height: "95vh", mt: {xs: 3, sm: 0}, width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap"}}>
                <Box sx={{
                    width: {xs: "90%", sm: "50%", md: "30%", lg: "20%"},
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center"
                }}>
                    <StyledCard variant={"outlined"} sx={{p: 3}}>
                        <Box sx={{
                            fontWeight: 900,
                            textAlign: "center",
                            width: "20%",
                            display: "flex",
                            alignSelf: "center"
                        }}>
                            <img src={logo} alt={"OGSCOFED logo"} width={"100%"}/>
                        </Box>
                        <Typography sx={{fontWeight: 600, mt: 3, textAlign: "center"}}>
                            Enter email address associated with account to reset your password
                        </Typography>
                        {memberError.error && <StyledAlert opened={memberError.error} message={memberError.errorMessage && memberError.errorMessage} />}
                        <Box component="form" noValidate autoComplete="off" id={"form"}>
                            <StyledTextField required label={"Email"} type={"email"} sx={{mt: 3}} name={"email"}
                                             onChange={dataInputChange} error={!!memberError.emailError}
                                             helperText={!!memberError.emailError ? memberError.emailError : ""}
                                             value={memberData?.email}/>
                        </Box>
                        <StyledButton variant={"contained"} size={"large"} sx={{mt:2}} onClick={handleSubmit} >
                            Reset password
                        </StyledButton>
                        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", mt: 10}}>
                            <StyledButton variant={"text"} size={"large"} sx={{ml: "-8px"}}
                                          onClick={() => history.push("/account/register")}>
                                Create account
                            </StyledButton>
                            <StyledButton variant={"contained"} size={"large"} onClick={() => history.push("/account/login")}>
                                Login
                            </StyledButton>
                        </Box>

                    </StyledCard>
                </Box>
            </Box>
        </>
    )
}

export default ForgotPasswordVerify;