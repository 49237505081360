import React from "react";
import {useLocation} from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from '@mui/material/CssBaseline';
import {styled} from "@mui/material";

import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Home from "./components/Home";
import Loader from "../../../Components/common/Loader";
import UserData from "../../../HelperFunctions/UserData";
import Members from "./components/Members";
import GetMembers from "./components/Members/components/GetMembers";
import GetReferees from "./components/Members/components/GetReferees";
import Loans from "./components/Loans";
import { Transactions } from "./components/Transactions";


const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(3, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Administration = () => {
    const location = useLocation();
    const [loading, setLoading] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const userData = UserData();
    const allMembers = GetMembers();
    const allReferees = GetReferees();
    // const userTransactions = UserTransactions();
    // const userWallet = UserWallet();
    // const userReferee = UserReferees();
    // const userBank = UserBank();
    const [open, setOpen] = React.useState(false);
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleMobileDrawerOpen = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    React.useEffect(()=>{
        setIsLoading(false);
    },[])
    return isLoading ? (
        <Loader loading={isLoading} />
    ) : (
        <Box sx={{display:"flex", background:"#F6F6F8", height:"100vh"}}>
            {loading && <Loader loading={loading}/>}
            <CssBaseline />
            <Sidebar drawerOpen={open} handleDrawerClose={handleDrawerClose} DrawerHeader={DrawerHeader} mobileOpen={mobileOpen} handleMobileDrawerOpen={handleMobileDrawerOpen} />
            <Header setLoading={setLoading} drawerOpen={open} userData={userData} handleDrawerOpen={handleDrawerOpen} handleMobileDrawerOpen={handleMobileDrawerOpen} />
            <Box component="main" sx={{ flexGrow: 1, p: 3, mt:3, width:"100%", overflowX: "auto" }}>
                <DrawerHeader />
                {location.pathname==='/account/administration/dashboard' && <Home />}
                {location.pathname==='/account/administration/members' && <Members allMembers={allMembers} allReferees={allReferees} setLoading={setLoading} />}
                {location.pathname==='/account/administration/members/loans' && <Loans allMembers={allMembers} setLoading={setLoading} />}
                {location.pathname==='/account/administration/members/transactions' && <Transactions allMembers={allMembers} setLoading={setLoading} />}
            </Box>
        </Box>
    )
}

export default Administration