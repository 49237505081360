import {styled} from "@mui/material";
import TextField from "@mui/material/TextField";

const StyledTextField = styled((props) => (
    <TextField variant={"outlined"} fullWidth {...props} />
))(({theme}) => ({
    textTransform: "none",
    boxShadow: "none",
    "&:focus": {
        color: theme.palette.primary.light
    }
}))


export default StyledTextField;