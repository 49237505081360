import Alert from "@mui/material/Alert";
import React from "react";
import Slide from "@mui/material/Slide";
import AlertTitle from "@mui/material/AlertTitle";


const StyledAlert = ({opened=false, message="", severe="error", title=undefined}) => {
    const [open, setOpen] = React.useState(opened);
    return (
        <Slide in={open} style={{ transitionDelay: open ? '500ms' : '0ms' }}>
            <Alert
                sx={{ mb: 2, mt: 1,fontSize: {xs:"0.75em", lg:"1em"}, "& .MuiAlert-icon":{fontSize:"2em"}, width:"100%!important" }}
                severity={severe}
            >
                {title && <AlertTitle sx={{fontSize: {xs:"1.25em", lg:"1.5em"}}}>{title}</AlertTitle>}
                {message ? message : "You have errors in your form"}
            </Alert>
        </Slide>
    )
}

export default StyledAlert;